<div class="select_wrap">
  <section class="top_box">
    <h2 class="hidden">플레이 방법</h2>
    <div class="how_to">
      <ol>
        <li><span>STEP1</span> 물건 선택하기</li>
        <li><span>STEP2</span> 미래기술 선택하기</li>
        <li><span>STEP3</span> 활용분야 선택하기</li>
        <li><span>STEP4</span> 나만의 새로운 인공지능 스마트 아이템 만들기</li>
      </ol>
    </div>
  </section>
  <section class="bottom_box">
    <button class="start_button" (click)="gameStart()">START</button>
  </section>
</div>
