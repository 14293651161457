import { AllSearchPipe } from './allSearch.pipe';
import { DocStudentPipe } from './docStudent.pipe';
import { SortPipe } from './sort.pipe';
/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightPipe } from './highlight.pipe';
import { DocPipe } from './doc.pipe';
import { SearchPipe } from './search.pipe';

@NgModule({
  declarations: [
    HighlightPipe,
    DocPipe,
    DocStudentPipe,
    AllSearchPipe,
    SortPipe,
    SearchPipe,
  ],
  imports: [CommonModule],
  exports: [
    HighlightPipe,
    DocPipe,
    DocStudentPipe,
    AllSearchPipe,
    SortPipe,
    SearchPipe,
  ],
})
export class PipeModule {}
