<main class="board_wrap">
  <!--사이드 메뉴-->
  <sidebar-share></sidebar-share>

  <!--메인보드-->
  <section class="main_board">
    <h2 class="hidden">그룹 작성 카드 보기</h2>
    <div *ngIf="student$ | async as studentFeed">
      <div class="for_wrap" *ngFor="let feedCard of studentFeed?.studentCard">
        <div
          [id]="feedCard"
          class="card_wrap"
          *ngIf="feedCard | doc: 'playUser' | async as cards"
          [ngStyle]="{ background: cards?.StepOneCard?.con.outBg }"
        >
          <div class="top_info">
            <div class="hashtag_area">
              <span [ngStyle]="{ background: cards?.StepOneCard?.con.innerBg }"
                >#{{ cards?.StepTwoCard?.title }}</span
              >
              <span [ngStyle]="{ background: cards?.StepThreeCard?.color }"
                >#{{ cards?.StepThreeCard?.card }}</span
              >
            </div>
            <div class="info_area">
              <span><img src="assets/imgs/star_icon.png" alt="top3" /></span>
              {{ (cards.studentId | doc: "student" | async)?.name }}
            </div>
          </div>

          <div class="txt_area">
            <p>
              {{ cards?.successCard }}
            </p>
            <div class="btn_wrap">
              <button (click)="ilike(cards.groupPlayId, cards.like)">
                <img
                  src="assets/imgs/heart_icon_off.png"
                  *ngIf="!likeTF(cards.like)"
                  alt="좋아요 비활성화"
                />
                <img
                  src="assets/imgs/heart_icon_on.png"
                  *ngIf="likeTF(cards.like)"
                  alt="좋아요 활성화"
                />
                {{ cards?.like.length + cards?.teacherlike }}
              </button>
              <button (click)="openComment(feedCard)">
                <img src="assets/imgs/comment_icon.png" alt="댓글 버튼" />
                {{ cards?.reviewsId.length }}
              </button>

              <button (click)="downloadImg(feedCard)" style="height: 2.7rem">
                <img
                  src="assets/imgs/download-icon.png"
                  alt="다운로드"
                  style="width: 1.7rem"
                />
                download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
