/** @format */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { switchMap, take, map, first } from 'rxjs/operators';
import { DbService } from './db.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<any>;
  constructor(
    public afAuth: AngularFireAuth,
    public db: DbService,
    public router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => (user ? db.doc$(`users/${user.uid}`) : of(null)))
    );

    this.handleRedirect();
  }

  getUser(): any {
    return this.user$.pipe(first()).toPromise();
  }
  isLiked(info): boolean {
    return !!(info.likes && info.likes.includes(this.userId));
  }

  uid(): any {
    return this.user$
      .pipe(
        take(1),
        map((u) => u && u.id)
      )
      .toPromise();
  }

  approval(): any {
    return this.user$
      .pipe(
        take(1),
        map((u) => u && u.activeSwitch)
      )
      .toPromise();
  }

  userId(): any {
    return this.user$.pipe(
      take(1),
      map((u) => u.id)
    );
  }

  info(): any {
    return this.user$.pipe(take(1)).toPromise();
  }

  infoR(): any {
    return this.user$
      .pipe(
        take(1),
        map((u) => u && u.selectedParts)
      )
      .toPromise();
  }

  async anonymousLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase.default
        .auth()
        .signInAnonymously()
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  async emailLogin(obj): Promise<any> {
    try {
      var r = await this.afAuth.signInWithEmailAndPassword(
        obj.email,
        obj.password
      );
      if (r) {
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 600);
        console.log('Successfully logged in!', r);
        localStorage.setItem('userId', r.user.uid);
      }
    } catch (error) {
      const code = error['code'];

      console.error('야기아냐?', error);
    }
  }

  loginUser(value): any {
    return new Promise<any>((resolve, reject) => {
      firebase.default
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  exitLogin(obj): any {
    return new Promise<any>((resolve, reject) => {
      try {
        var r = this.afAuth.signInWithEmailAndPassword(obj.email, obj.password);

        if (r) {
          console.log('Successfully logged in!');
        }
      } catch (error) {
        let code = error['code'];

        resolve(error);
      }
    });
  }

  loginWithToken(token): any {
    // tslint:disable-next-line:no-shadowed-variable
    return new Promise<any>((resolve, reject) => {
      firebase.default
        .auth()
        .signInWithCustomToken(token)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  resetPassword(email): any {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }

  async updateUserData(studendId, obj?): Promise<any> {
    //사용자 데이터를 firestore에 업로드//
    const path = `student/${studendId}`;
    const createdAt = new Date().toISOString();
    const data = Object.assign(
      {
        studendId,
        createdAt,
      },
      obj
    );
    await this.db.updateAt(path, data);
    // return this.alert.presentAlert('가입완료', '가입이 완료되었습니다. 회원가입을 진심으로 감사드립니다.')
  }

  async signOut(): Promise<any> {
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }

  registerUser(value): any {
    return new Promise<any>((resolve, reject) => {
      firebase.default
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  async register(email, password, obj): Promise<any> {
    try {
      var r = await this.afAuth.createUserWithEmailAndPassword(email, password);

      if (r) {
        this.router.navigate(['/']);
        localStorage.setItem('uid', r.user.uid);
        return await this.updateUserData(r.user.uid, obj);
      }
    } catch (error) {
      let code = error['code'];
      console.error(error);
    }
  }

  //// GOOGLE AUTH
  public async handleRedirect(): Promise<any> {
    if (Boolean(this.isRedirect())) {
      return null;
    }

    const result = await this.afAuth.getRedirectResult();

    if (result.user) {
      await this.updateUserData(result.user);
    }

    await this.setRedirect('false');
    return result;
  }

  setRedirect(val): any {
    localStorage.setItem('authRedirect', val);
  }

  isRedirect(): any {
    return localStorage.getItem('authRedirect');
  }

  changePassword(oldPassword, newPassword): any {
    return new Promise((resolve, reject) => {
      const user = firebase.default.auth().currentUser;
      return this.afAuth
        .signInWithEmailAndPassword(user.email, oldPassword)
        .then((sucess) => {
          user
            .updatePassword(newPassword)
            .then((sucess) => {
              resolve(true);
            })
            .catch((error) => {
              console.log('error', error);
              let code = error['code'];
              reject(false);
            });
        })
        .catch((error) => {
          reject(false);

          let code = error['code'];
          // this.alert.showErrorMessage(code);
          console.log('oldPasswordIncorrect', error);
        });
    });
  }

  exitUser(): any {
    return new Promise((resolve, reject) => {
      const user = firebase.default.auth().currentUser;
      this.db
        .updateAt(`student/${user.uid}`, { exitSwitch: true })
        .then(() => {
          user
            .delete()
            .then(() => {
              localStorage.clear();

              // this.router.navigateByUrl('/login');
              reject(false);
            })
            .catch((error) => {
              console.log('error', error);
            });
        })
        .catch((error) => {
          console.log('error', error);
        });
    });
  }

  sendEmailVerificationUser(user): any {
    return new Promise((resolve, reject) => {
      if (firebase.default.auth().currentUser) {
        user
          .sendEmailVerification()
          .then(() => {
            console.log('LOG Out');
            resolve({});
          })
          .catch((error) => {
            reject();
          });
      }
    });
  }

  userDetails(): any {
    return firebase.default.auth().currentUser;
  }

  logoutUser(): any {
    return new Promise((resolve, reject) => {
      if (firebase.default.auth().currentUser) {
        firebase.default
          .auth()
          .signOut()
          .then(() => {
            console.log('LOG Out');
            resolve({});
          })
          .catch((error) => {
            reject();
          });
      }
    });
  }
}
