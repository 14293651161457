import { AlertDesignOnebuttonComponent } from './../alert-design-onebutton/alert-design-onebutton.component';
import { InputNumberService } from './../../services/input-number.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  NavigationExtras,
  Router,
  ActivatedRoute,
  NavigationStart,
  Event as NavigationEvent,
} from '@angular/router';
import { AlertDesignJustWordComponent } from '../alert-design-just-word/alert-design-just-word.component';
import { AlertDesignComponent } from '../alert-design/alert-design.component';
import { FutureCardComponent } from '../template/future-card/future-card.component';
import { ItemCardBigComponent } from '../template/item-card-big/item-card-big.component';
import { CardBoardComponent } from '../template/item-card/card-board/card-board.component';
import { UtilizationCardComponent } from '../template/utilization-card/utilization-card.component';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { filter, map, take, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { of, combineLatest, pipe } from 'rxjs';
import { UserInfoService } from 'src/app/services/userInfo.service';

@Component({
  selector: 'app-play-personal',
  templateUrl: './play-personal.component.html',
  styleUrls: ['./play-personal.component.css'],
})
export class PlayPersonalComponent implements OnInit, OnDestroy {
  cardData: any;
  pickType;
  pickCard;

  groupPlaySwitch;
  pickItemCard: any;
  pickFutureCard: any;
  pickUtilizationCard: any;
  itemCardType;
  card = true;
  result;
  onlyOne = 0;

  front = false;
  teacherInfo;

  public selectedStepCards: any = [];
  public selectedStepTitles: any = [];

  public word;
  public futureWord;
  public utilizationWord;
  public disabledutilization;
  public disabledutilization2 = 'all';
  public disabledfuture = 'all';
  public disabled;
  public stepIcon = '1';
  public gameType;
  public progressbar = 34;
  public userId;
  public teachercode;
  public playCards;

  public gameOver;
  public noClick = false;

  level: number = 0;
  only: number = 0;

  backUrl;
  backInfo;
  playRoomChk: boolean = null;

  currentTeacherSub$;
  startSwitch: boolean = false;
  checkExitTeacher(): void {
    const teacherCode: string = localStorage.getItem('teacherCode');
    this.currentTeacherSub$ = this.dbService
      .collection$(`teacher`, (ref) =>
        ref.where('teacherCode', '==', teacherCode)
      )
      .pipe(take(1))
      .subscribe((teachers: Array<any>) => {
        if (teachers.length == 0) {
          return false;
        }

        const teacher: any = teachers[0];
        if (teacher) {
          this.dbService
            .doc$(`teacher/${teacher.teacherId}`)
            .subscribe((teacherTmp) => {
              if (teacherTmp && !teacherTmp.playSwitch && this.startSwitch) {
                // 선생님이 중간에 나감
                // this.exitTeacherForce();
                this.router.navigate(['/']);
              }
            });
        }
      });
  }

  async exitTeacherForce() {
    const dialogRef = await this.dialog.open(AlertDesignJustWordComponent, {
      disableClose: true,
      panelClass: 'word_pop',
      data: {
        txt: '선생님이 게임을 강제종료 하였습니다.',
      },
    });
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    // private authService: AuthService,
    private dbService: DbService,
    private route: ActivatedRoute,
    public inputnumber: InputNumberService,
    public cardinfo: UserInfoService
  ) {
    this.userId = localStorage.getItem('userId');
    this.route.queryParams.pipe(take(1)).subscribe((data) => {
      if (data.back == 'back') {
        this.cardinfo
          .getBeforeCardInfo()
          .pipe(take(1))
          .subscribe((data) => {
            if (data[0] && data[1] && data[2]) {
              this.disabled = 'all';
              this.pickItemCard = data[0].result;
              this.itemCardType = data[0].itemCard;

              this.disabledfuture = 'all';
              this.pickFutureCard = data[1].result;

              this.pickUtilizationCard = data[2].result;
              this.disabledutilization = data[2].result?.card;
              this.disabledutilization2 = '';

              this.stepIcon = '3';
              this.progressbar = 100;
              this.noClick = true;
            }
          });
      }
      if (localStorage.getItem('gamestop') == 'end') {
        this.gameOver = localStorage.getItem('gamestop');
      } else {
        this.gameOver = data.ing;
      }
      this.gameType = data.gameType;
      if (data.only) {
        this.only = data.only;
      }
    });
  }

  async ngOnInit() {
    if (localStorage.getItem('forcestop')) {
      this.noClick = true;
      this.disabled = 'all';
      this.disabledfuture = 'all';
      this.disabledutilization2 = 'all';
    }
    this.playCards = this.dbService
      .collection$(`student`, (ref) =>
        ref.where('studendId', '==', this.userId)
      )
      .pipe(
        switchMap((playCard: any) => {
          if (playCard.length > 0) {
            let cards$ = [];
            const cards = playCard[0].playGame;
            if (cards.length == 10 && this.gameOver == 'end') {
              localStorage.setItem('gamestop', 'end');
              this.noClick = true;
              this.disabled = 'all';
              this.disabledfuture = 'all';
              this.disabledutilization2 = 'all';
              const dialogRef = this.dialog.open(
                AlertDesignOnebuttonComponent,
                {
                  data: {
                    txt: '피드가 모두 완성되었습니다.',
                  },
                }
              );
            } else {
              localStorage.removeItem('gamestop');
              cards.forEach((cardInfo) => {
                let tmp$ = this.dbService.doc$(`playUser/${cardInfo}`);
                cards$.unshift(tmp$);
              });

              if (cards$.length > 0) {
                return combineLatest(cards$);
              } else {
                return of([]);
              }
            }
          } else {
            return of([]);
          }
        })
      );

    this.playCards.subscribe((card) => {
      if (card && card.length > 0) {
        card.forEach((element) => {
          if (element.StepTwoCard) {
            this.selectedStepTitles.push(element.StepTwoCard.title);
          }
          if (element.StepThreeCard) {
            this.selectedStepCards.push(element.StepThreeCard.card);
          }
        });
      }
    });

    this.futureWord = this.dbService.docOne$('futureWord/qQHCNGrNdqgwEuaTrGv1');
    this.utilizationWord = this.dbService.docOne$(
      'utilizationWord/6PUHZFKUDWn41vU1sqLD'
    );

    if (this.only == 0) {
      this.getData();
    }
  }

  // 2단계 선택 방지 함수
  disabledChkStepTwoCard(value) {
    const val = value.title;
    if (this.selectedStepTitles.indexOf(val) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // 3단계 선택 방지 함수
  disabledChkStepThreeCard(value) {
    const val = value.card;
    if (this.selectedStepCards.indexOf(val) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // 데이터 불러오는 함수
  async getData() {
    await this.dbService
      .doc$(`student/${this.userId}`)
      .pipe(take(1))
      .subscribe(async (data) => {
        if (!data.playGame || data.playGame.length == 0) {
          this.level = 0;
        } else {
          this.level = 1;
        }
        this.teachercode = localStorage.getItem('teacherCode');
        this.checkExitTeacher();

        this.backUrl = this.router.events.subscribe(async (e) => {
          if (e instanceof NavigationStart) {
            if (e.url == '/play-select') {
              this.backInfo = await this.dbService.collection$(
                `teacher`,
                (ref) => ref.where('teacherCode', '==', this.teachercode)
              );
              await this.backInfo.pipe(take(1)).subscribe(async (data) => {
                await this.dbService.updateAt(`teacher/${data[0].teacherId}`, {
                  groupStudent:
                    firebase.default.firestore.FieldValue.arrayRemove(
                      this.userId
                    ),
                });
              });
              this.backUrl.unsubscribe();
            }
          }
        });
        if (this.gameType == 'group' && this.onlyOne == 0) {
          this.openWaitPlay();
        }
      });
  }

  // 나가기 얼럿
  openExitAlert(): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { type: 'leftOk', txt: '플레이를 종료 하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.data == 'ok') {
        this.router.navigate(['/home']);
      }
    });
  }

  // 선생님이 플레이하기 전까지 대기 얼럿
  async openWaitPlay() {
    const dialogRef = await this.dialog.open(AlertDesignJustWordComponent, {
      disableClose: true,
      panelClass: 'word_pop',
      data: {
        txt: '플레이가 곧 시작합니다. \n 잠시만 기다려주세요.',
        teacherCode: this.teachercode,
        openType: true,
        exitType: false,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        this.startSwitch = true;
        console.log('플레이 시작 닫힘', this.startSwitch);
        if (data.onlyOne) {
          this.onlyOne = data.onlyOne;
        }
        // 여기서 playRoom 구독!
        this.dbService
          .doc$(`student/${this.userId}`)
          .pipe(take(1))
          .subscribe((user) => {
            if (user && user.currentPlay) {
              this.dbService
                .doc$(`playRoom/${user.currentPlay}`)
                .subscribe((play) => {
                  this.playRoomChk = play.activeSwitch;

                  // console.log('Play', play);
                  if (!this.playRoomChk) {
                    this.teacherWaitPlay(play.reason);
                  }
                });
            }
          });
      });
  }

  // 종료 알림 창 오픈 함수
  async exitWaitPlay() {
    console.log(this.teachercode);
    const dialogRef = await this.dialog.open(AlertDesignJustWordComponent, {
      disableClose: true,
      panelClass: 'word_pop',
      data: {
        txt: '플레이 시간이 종료되었습니다.',
      },
    });
  }

  // 피드 이동 함수
  teacherWaitPlay(txt) {
    if (txt == '그룹피드로 이동하겠습니다.') {
      const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
        disableClose: true,
        data: {
          txt: txt,
        },
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data.data == 'ok') {
          localStorage.setItem('forcestop', 'forcestop');
          this.router.navigate(['/play-group-all'], {
            queryParams: {
              teacherCode: this.teachercode,
            },
          });
        }
      });
    } else {
      console.log(this.teachercode);
      const dialogRef = this.dialog.open(AlertDesignJustWordComponent, {
        disableClose: true,
        panelClass: 'word_pop',
        data: {
          txt,
        },
      });
      localStorage.removeItem('forcestop');
      setTimeout(() => {
        dialogRef.close();
        this.router.navigate(['/home'], { replaceUrl: true });
      }, 2000);
    }
  }

  // 상단 카드 보드 컴포넌트
  openItemBoard(card: any): any {
    const cardInfo = { type: 'item', card };
    this.ItemCardBig(cardInfo);
  }

  // 왼쪽 카드 보드 컴포넌트
  openfutureCard(futureWord): any {
    this.stepIcon = '2';
    const dialogRef = this.dialog.open(FutureCardComponent, {
      panelClass: 'future_card_pop',
      data: futureWord,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.ItemCardBig(data);
      }
    });
  }

  // 오른쪽 카드 보드 컴포넌트
  openUtilizationCard(utilizationWord): any {
    const dialogRef = this.dialog.open(UtilizationCardComponent, {
      panelClass: 'future_card_pop',
      data: utilizationWord,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.ItemCardBig(data);
      }
    });
  }

  // 카드 선택 화면
  ItemCardBig(data): any {
    let ItemCardBig = this.dialog.open(ItemCardBigComponent, {
      disableClose: true,

      panelClass: 'big_card_pop',
      data: data,
    });

    ItemCardBig.afterClosed().subscribe((data) => {
      if (!data || data.reason == 'cancel') {
        if (data.type == 'item') {
        } else if (data.type == 'fCard') {
          this.openfutureCard(data.alldata);
        } else {
          this.disabled = data.title;
          this.openUtilizationCard(data.alldata);
        }
      } else if (data.pickCard == 'item') {
        ////// 선택시
        this.pickCard = 'item';
        this.disabled = data.itemCard;
        this.pickItemCard = data.result;
        this.itemCardType = data.itemCard;
        this.front = true;
        this.cardinfo.updateCardInfo('1', data);
      } else if (data.pickCard == 'future') {
        this.pickCard = 'future';
        this.pickFutureCard = data.result;
        this.disabledfuture = data.result;
        this.disabled = 'all';
        this.front = true;
        this.cardinfo.updateCardInfo('2', data);
      } else if (data.pickCard == 'utilization') {
        this.pickCard = 'utilization';
        this.pickUtilizationCard = data.result;
        this.disabledutilization = data.result.card;
        this.disabledfuture = 'all';
        this.cardinfo.updateCardInfo('3', data);
      }
    });
  }

  // 뒤로 이동 함수
  goBack(): any {
    if (this.stepIcon == '3') {
      this.pickCard = 'future';
      this.stepIcon = '2';
      if (this.stepIcon == '2') {
        this.disabled = 'all';
        this.disabledutilization2 = 'all';
      }
      this.progressbar = 67;
      this.disabledfuture = this.pickFutureCard;
      this.pickUtilizationCard = '';
      this.disabledutilization = '';
    } else if (this.stepIcon == '2') {
      this.pickCard = 'item';
      this.stepIcon = '1';
      if (this.stepIcon == '1') {
        this.disabledfuture = 'all';
        this.disabledutilization2 = 'all';
        this.noClick = false;
      }
      this.progressbar = 34;
      this.pickFutureCard = '';
      this.disabled = this.itemCardType;
    }
  }

  // 글쓰기로 넘어가기
  goNext(type1, type2, type3): any {
    let tmp = {};
    tmp['type1'] = type1;
    tmp['type2'] = type2;
    tmp['type3'] = type3;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        type1: type1,
        type2: type2,
        type3: type3,
        gameType: this.gameType,
      },
    };
    this.gameOver = 'start';
    this.router.navigate(['play-personal-write'], navigationExtras);
  }

  // 선택 아이템 정보 불러오는 함수
  goItem(): any {
    if (this.pickCard == 'item') {
      this.stepIcon = '2';
      if (this.stepIcon == '2') {
        this.front = false;
        this.disabled = 'all';
        this.disabledfuture = '';
        this.disabledutilization2 = 'all';
        this.noClick = true;
      }
      this.progressbar = 67;
    } else if (this.pickCard == 'future') {
      this.stepIcon = '3';
      this.front = false;
      if (this.stepIcon == '3') {
        this.disabled = 'all';
        this.disabledfuture = 'all';
        this.disabledutilization2 = '';
        this.noClick = true;
      }
      this.progressbar = 100;
    } else if (this.pickCard == 'utilization') {
      this.stepIcon = '3';
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('gamestop');
    if (this.currentTeacherSub$) {
      this.currentTeacherSub$.unsubscribe();
    }
  }
}
