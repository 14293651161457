import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { CardBoardComponent } from '../item-card/card-board/card-board.component';

@Component({
  selector: 'item-card-big',
  templateUrl: './item-card-big.component.html',
  styleUrls: ['./item-card-big.component.css'],
})
export class ItemCardBigComponent implements OnInit {
  card;
  type;
  allitem;

  itemCard = {
    img: '',
    txt: '',
    onlyTxt: '',
    outBg: '',
    innerBg: '',
  };

  constructor(
    public dialogRef: MatDialogRef<ItemCardBigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.type = data.type;
    this.card = data.card;
    this.allitem = data.alldata;
  }

  ngOnInit(): void {
    this.itemCardPick();
  }

  cardPick(selectData, card): any {
    this.dialogRef.close({
      reason: 'select',
      result: selectData,
      pickCard: card,
      itemCard: this.card,
    });
  }

  itemCardPick(): any {
    if (this.card == 'mirror') {
      this.itemCard.img = 'assets/imgs/mirror_icon.png';
      this.itemCard.txt = '재미있는 생활을 \n 만드는 스마트 거울';
      this.itemCard.onlyTxt = '재미있는 \n 생활을 \n 만드는 \n 스마트 거울';
      this.itemCard.outBg = '#EA6687';
      this.itemCard.innerBg = '#D84972';
    } else if (this.card == 'speaker') {
      this.itemCard.img = 'assets/imgs/speaker_icon.png';
      this.itemCard.txt = '즐겁고 편리한 생활을 \n만들어주는 스마트 스피커';
      this.itemCard.onlyTxt =
        '즐겁고 편리한 \n 생활을 \n 만들어주는 \n 스마트 스피커';
      this.itemCard.outBg = '#F4B100';
      this.itemCard.innerBg = '#F49302';
    } else if (this.card == 'bicycle') {
      this.itemCard.img = 'assets/imgs/bicycle_icon.png';
      this.itemCard.txt = '아름다운 세상을\n 누비는 스마트 자전거';
      this.itemCard.onlyTxt = '아름다운 \n 세상을 누비는 \n 스마트 자전거';
      this.itemCard.outBg = '#6D3582';
      this.itemCard.innerBg = '#502785';
    } else if (this.card == 'glasses') {
      this.itemCard.img = 'assets/imgs/glasses_icon.png';
      this.itemCard.txt = '보이지 않던 \n 모습까지 보여주는 스마트 안경';
      this.itemCard.onlyTxt =
        '보이지 않던 \n 모습까지 \n 보여주는 \n 스마트 안경';
      this.itemCard.outBg = '#0064B3';
      this.itemCard.innerBg = '#003E98';
    } else if (this.card == 'blackboard') {
      this.itemCard.img = 'assets/imgs/blackboard_icon.png';
      this.itemCard.txt = '스마트한 학교생활을 \n도와주는 스마트 칠판';
      this.itemCard.onlyTxt =
        '스마트한 \n 학교생활을 \n 도와주는 \n 스마트 칠판';
      this.itemCard.outBg = '#52BA9F';
      this.itemCard.innerBg = '#009EA0';
    } else {
      this.itemCard.img = 'assets/imgs/bag_icon.png';
      this.itemCard.txt = '어느 곳에서나 \n함께하는 스마트 가방';
      this.itemCard.onlyTxt = '어느곳에서나 \n 함께하는 \n 스마트 가방';
      this.itemCard.outBg = '#C0CC38';
      this.itemCard.innerBg = '#5DB665';
    }
  }

  popClose(): any {
    this.dialogRef.close({
      reason: 'cancel',
      type: this.type,
      alldata: this.allitem,
    });
  }
}
