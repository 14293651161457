import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validator } from '../../../validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loadPage = document.getElementById('load');

  public loading = true;
  public form: FormGroup;
  public loginErr = true;
  public exitUser = true;
  public loginInfo = {
    id: '',
    password: '',
  };
  public student;
  public teacher;
  public whatType = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private dbService: DbService,
    private spinner: NgxSpinnerService,
    public formBuilder: FormBuilder
  ) {
    this.form = formBuilder.group({
      id: Validator.idValidator,
      password: Validator.passwordValidator,
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 2500);
  }

  // 로그인 함수 (유/무 체크)
  async loginCheck() {
    this.spinner.show();
    const credentials = {
      email: `${this.form.controls.id.value}@gmail.com`,
      password: this.form.controls.password.value,
    };

    await this.authService
      .loginUser(credentials)
      .then(async (success) => {
        this.student = this.dbService.doc$(`student/${success.user.uid}`);
        this.teacher = this.dbService.doc$(`teacher/${success.user.uid}`);

        // AI 리뉴어는 무조건 선생님만 로그인 가능함으로
        // 선생님 init
        this.initTeacher(success.user.uid);

        await this.teacher.pipe(take(1)).subscribe((data) => {
          if (data.exitSwitch) {
            this.authService.logoutUser();
            localStorage.clear();
            this.exitUser = false;
            this.loginInfo.id = '';
            this.loginInfo.password = '';
            this.spinner.hide();
          } else {
            this.spinner.hide();
            localStorage.setItem('userType', 'teacher');
            localStorage.setItem('userId', success.user.uid);
            this.router.navigate(['/home']);
          }
        });
      })
      .catch((error) => {
        this.loginErr = false;
        this.spinner.hide();
      });
  }

  // groupFeed 카드, 현재 학습중인 학생들 init
  // (중간에 강제로 앱을 나갔다가 들어왔을 수 있는 경우를 위해)
  async initTeacher(uid: string): Promise<void> {
    this.initGroupCard(uid);
    this.initStudent(uid);
  }

  // groupFeed 카드 init
  initGroupCard(uid: string): void {
    this.dbService
      .collection$(`groupFeed`, (ref) => ref.where('teacherId', '==', uid))
      .pipe(take(1))
      .subscribe((groupFeeds: Array<any>) => {
        if (groupFeeds.length > 0) {
          this.dbService.updateAt(`groupFeed/${groupFeeds[0].id}`, {
            studentCard: [],
          });
        }
      });
  }

  // 학생들 init (수업중 switch도 init)
  initStudent(uid: string): void {
    this.dbService.updateAt(`teacher/${uid}`, {
      playSwitch: false,
      readySwitch: false,
    });
  }

  // 회원가입 페이지 이동
  signup(): any {
    this.router.navigate(['/login-signup']);
  }
}
