import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './pages/account/login/login.component';
import { MypageComponent } from './pages/mypage/mypage.component';
import { PlayLoadingComponent } from './pages/play-loading/play-loading.component';
import { PlayPersonalWriteComponent } from './pages/play-personal-write/play-personal-write.component';
import { PlayPersonalComponent } from './pages/play-personal/play-personal.component';
import { PlaySelectComponent } from './pages/play-select/play-select.component';
import { TermsComponent } from './pages/terms/terms/terms.component';

import { AuthGuard } from './guards/auth.guard';
import { PlayGroupAllComponent } from './pages/play-group-all/play-group-all.component';
import { BoastingboardComponent } from './pages/boastingboard/boastingboard.component';
import { PlayComponent } from './pages/teacher/play/play.component';
import { MypageRegisterTComponent } from './pages/teacher/mypage-register-t/mypage-register-t.component';
import { MypageRecordTClassComponent } from './pages/teacher/mypage-record-t-class/mypage-record-t-class.component';
import { InputCodeComponent } from './pages/input-code/input-code.component';
import { InputNameComponent } from './pages/input-name/input-name.component';
import { PlayStartComponent } from './pages/play-start/play-start.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'mypage',
    component: MypageComponent,
  },
  {
    path: 'play-loading',
    component: PlayLoadingComponent,
  },
  {
    path: 'play-select',
    component: PlaySelectComponent,
  },
  {
    path: 'play-personal',
    component: PlayPersonalComponent,
  },
  {
    path: 'play-personal-write',
    component: PlayPersonalWriteComponent,
  },
  {
    path: 'play-group-all',
    component: PlayGroupAllComponent,
  },
  {
    path: 'boastingboard',
    component: BoastingboardComponent,
  },
  {
    path: 'play-t',
    component: PlayComponent,
  },
  {
    path: 'mypage-t',
    component: MypageRegisterTComponent,
  },
  {
    path: 'mypage-record',
    component: MypageRecordTClassComponent,
  },
  {
    path: 'input-code',
    component: InputCodeComponent,
  },
  {
    path: 'input-name',
    component: InputNameComponent,
  },
  {
    path: 'play-start',
    component: PlayStartComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
