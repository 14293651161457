import { DbService } from './../../services/db.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(public db: DbService) {
    // 선생님이 home 진입할 경우
    // 학생들 & 그룹 피드 진행중 switch init
    if (localStorage.getItem('userType') == 'teacher') {
      this.db.updateAt(`teacher/${localStorage.getItem('userId')}`, {
        groupStudent: [],
        playSwitch: false,
        readySwitch: false,
      });
    }
  }

  ngOnInit(): void {
    localStorage.removeItem('gamestop');
  }
}
