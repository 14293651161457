import { DbService, leftJoinQuery } from './db.service';
import { Injectable, Output } from '@angular/core';
import { BehaviorSubject, interval, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDesignJustWordComponent } from '../pages/alert-design-just-word/alert-design-just-word.component';
import { take } from 'rxjs/operators';
import { TimerService } from './timer.service';

@Injectable({
  providedIn: 'root',
})
export class InputNumberService {
  inputnumber: number = 0;
  @Output() progressBar;
  minSec;
  seconds: number = 10;
  addseconds;
  sec;
  addTime;
  timer$ = of();
  timer1$ = of();
  playSwitch;
  userId;
  user$;
  teacherCode;

  teacherId;

  sub;
  sub2;

  timerSwitch: boolean = true;

  constructor(
    public db: DbService,
    public dialog: MatDialog,
    public timer: TimerService
  ) {
    // this.userId = this.authService.userDetails().uid;
    this.userId = localStorage.getItem('userId');
    this.db
      .doc$(`student/${this.userId}`)
      .pipe(take(1))
      .subscribe(async (data) => {
        this.teacherCode = data.teacherCode;
      });
  }

  ////////////////////////////////////////////////////////////
  async StartTimer(value) {
    if (value) {
      this.timer.countdownSecond(this.seconds).subscribe((data) => {
        this.sec = data.count;
        this.minSec = data.display;
        this.progressBar = (this.sec * 100) / this.seconds;
        if (this.sec == 0) {
          this.exitWaitPlay();
          this.timer.stop();
          this.minSec = '';
        }
      });
    }
  }

  async addTimer(addTime) {
    if (this.sec == 0) {
      this.addseconds = addTime;
    } else {
      this.addseconds = addTime + this.sec;
    }
    this.timer.countdownSecond(this.addseconds).subscribe((data) => {
      this.sec = data.count;
      this.minSec = data.display;
      this.progressBar = (this.sec * 100) / this.addseconds;
      if (this.sec == 0) {
        this.exitWaitPlay();
        this.timer.stop();
        this.minSec = '';
      }
    });
  }

  async EndTimer() {
    this.timer.stop();
    this.minSec = '';
  }
  //////////////////////////////////////////////////

  async initTimer(value) {
    if (value) {
      this.timer$ = interval(1000);
      this.getTimer();
    } else {
      console.log('작동안함');
    }
  }

  async getTimer() {
    this.sub = this.timer$.subscribe((sec: any) => {
      const addTimer = this.seconds;
      this.progressBar = 100 - (sec * 100) / addTimer;
      this.sec = sec;
      // console.log('services initTimer', sec);
      if (this.sec === addTimer) {
        this.exitWaitPlay();
        this.sub.unsubscribe();
      }
    });
  }

  async addGetTimer(addtime) {
    this.addseconds = addtime;
    this.sec = 0;
    this.timer1$ = interval(1000);
    this.sub2 = await this.timer1$.subscribe((sec2: any) => {
      this.progressBar = 100 - (sec2 * 100) / addtime;
      this.sec = sec2;
      // console.log('add time', sec2);
      if (this.sec === addtime) {
        this.db.updateAt(`teacher/${this.teacherId}`, {
          addTimer: 0,
        });
        this.exitWaitPlay();
        this.sub2.unsubscribe();
      }
    });
  }

  async exitWaitPlay() {
    const dialogRef = await this.dialog.open(AlertDesignJustWordComponent, {
      disableClose: true,
      panelClass: 'word_pop',
      data: {
        txt: '플레이 시간이 종료되었습니다.',
        teacherCode: this.teacherCode,
        exitType: true,
        openType: false,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.teacher) {
        this.teacherId = data.teacher;
        this.db.updateAt(`teacher/${data.teacher}`, {
          playSwitch: false,
          readySwitch: false,
          addTimer: 0,
        });
      }
    });
  }
}
