import { AuthService } from './../../../services/auth.service';
import { DbService } from './../../../services/db.service';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { AlertDesignComponent } from '../../alert-design/alert-design.component';

@Component({
  selector: 'app-mypage-register-t-class',
  templateUrl: './mypage-register-t-class.component.html',
  styleUrls: ['./mypage-register-t-class.component.css'],
})
export class MypageRegisterTClassComponent implements OnInit {
  type1 = 'placeholder';
  object = 'placeholder';

  lecture = '';
  grade;
  class;

  etcClass;
  etcType;
  etcObject;

  exit$;
  exitId;

  constructor(
    public dialogRef: MatDialogRef<MypageRegisterTClassComponent>,
    public db: DbService,
    public auth: AuthService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.exitId = data.exit;
    this.exit$ = this.db.doc$(`exitClass/${this.exitId}`);
  }

  ngOnInit(): void {}

  // 창 닫기 함수
  close(): void {
    this.dialogRef.close();
  }

  // 학년 선택 함수
  gradeSelect(event) {
    this.grade = event.target.value;
  }

  // 반 선택 함수
  classSelect(event) {
    this.class = event.target.value;
  }

  // 시간 계산 함수
  getMinutes(start, end) {
    return new Date(end).getMinutes() - new Date(start).getMinutes();
  }

  etcClassss(event) {
    this.etcClass = event;
  }

  // 등록 알림창 함수
  finalRegister() {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: {
        txt: '등록하시겠습니까?',
        sub: '(한 번 등록하시면 수정이 불가하니 한 번 더 확인해주시기 바랍니다.)',
        ok: '등록',
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data.data == 'ok') {
        if (this.type1 == '기타') {
          this.type1 = this.etcType;
        }
        if (this.object == '기타') {
          this.object = this.etcObject;
        }
        if (this.class == '7') {
          this.class = this.etcClass;
        }

        this.db.updateAt(`exitClass/${this.exitId}`, {
          type: this.type1,
          className: this.lecture,
          target: this.object,
          grade: this.grade,
          class: this.class,
        });

        this.dialogRef.close();
      }
    });
  }
}
