<header-share></header-share>

<main id="content" *ngIf="user$ | async as user">
  <section class="left_box">
    <table>
      <tr class="table_header">
        <th width="20%">번호</th>
        <th width="80%">이름</th>
      </tr>

      <tr *ngFor="let use of user?.groupStudent; let i = index">
        <td class="num">
          {{ i + 1 }}
        </td>
        <td>
          {{ (use | docStudent: "student" | async)?.name }}
        </td>
      </tr>
    </table>
    <!--입장한 학생 리스트 없을때-->
    <div class="nobody" *ngIf="user?.groupStudent?.length == 0">
      입장한 학생이 없습니다.
    </div>
  </section>

  <section class="right_box" *ngIf="cards$ | async as feedBooks">
    <div class="right_top">
      <button
        [disabled]="user?.groupStudent?.length == 0 || disableButton"
        (click)="gameStart(user?.groupStudent)"
      >
        시작
      </button>
      <div class="time_area">
        <p
          *ngIf="timerStr"
          (click)="openMoreTime(disableButton, user.playSwitch)"
        >
          {{ timerStr }}
        </p>
        <p
          *ngIf="!timerStr"
          class="time_txt"
          (click)="openMoreTime(disableButton, user.playSwitch)"
        >
          시간추가
        </p>

        <span
          >피드수 ({{
            (user?.teacherCode | doc: "groupFeed" | async)?.studentCard.length
          }}/{{ user?.groupStudent?.length }})</span
        >
      </div>

      <!-- [disabled]="!user.playSwitch || feedBooks.length == 0" -->
      <button
        *ngIf="nextClass == 1"
        (click)="openCloseClass()"
        [disabled]="!disableButton"
      >
        그룹피드 이동
      </button>
      <button
        *ngIf="nextClass == 2"
        [disabled]="(user$ | async)?.playSwitch == false"
        (click)="openCloseClass()"
      >
        종료
      </button>
    </div>

    <div class="card_all">
      <!--card area-->
      <div class="card_wrap" *ngFor="let stuFeed of feedBooks">
        <div class="card_top">
          <div class="hastag">
            <span>#{{ stuFeed?.StepTwoCard?.title }}</span>
            <span>#{{ stuFeed?.StepThreeCard?.card }}</span>
          </div>
          <div class="info">
            <img src="assets/imgs/star_icon.png" alt="좋아요 순위 높음" />
            {{ stuFeed?.studentId?.name }}
          </div>
        </div>

        <div class="txt_area">
          <p>
            {{ stuFeed?.successCard }}
          </p>
          <div class="btn_box">
            <button
              class="like"
              (click)="
                ilike(stuFeed.groupPlayId, stuFeed?.like, stuFeed?.teacherlike)
              "
            >
              <img
                src="assets/imgs/heart_icon_off.png"
                *ngIf="!likeTF(stuFeed.like)"
                alt="좋아요 비활성화"
              />
              <img
                src="assets/imgs/heart_icon_on.png"
                *ngIf="likeTF(stuFeed.like)"
                alt="좋아요 활성화"
              />
              {{ stuFeed?.like?.length + stuFeed?.teacherlike }}
            </button>
            <button class="comment" (click)="openComment(stuFeed.groupPlayId)">
              <img src="assets/imgs/comment_icon.png" alt="댓글보기" />
              {{ stuFeed?.reviewsId?.length }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<footer-share></footer-share>
