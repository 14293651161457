import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tip-com',
  templateUrl: './tip-com.component.html',
  styleUrls: ['./tip-com.component.css']
})
export class TipComComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TipComComponent>,) { }

  ngOnInit(): void {
  }


  popClose(): any {
    this.dialogRef.close();
  }


}
