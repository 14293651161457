import { Location } from '@angular/common';
import { InputNumberService } from './../../services/input-number.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TipComComponent } from '../template/tip-com/tip-com.component';
import * as firebase from 'firebase/app';
import { AlertDesignComponent } from '../alert-design/alert-design.component';
import { DbService } from 'src/app/services/db.service';
import { filter, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-play-personal-write',
  templateUrl: './play-personal-write.component.html',
  styleUrls: ['./play-personal-write.component.css'],
})
export class PlayPersonalWriteComponent implements OnInit {
  to1 = '을/를';
  to2 = '하여';
  to3 = '하는';
  text1;
  text2;
  text3;
  text4;
  textAll;
  data;
  iCardType;
  fCardType;
  uCardType;
  gameType;
  pickText = false;
  playCount;

  playing = true;

  personalPlay = {
    personalPlayId: '',
    studentId: '',
    StepOneCard: '',
    StepTwoCard: '',
    StepThreeCard: '',
    dateCreated: new Date().toISOString(),
    successCard: '',
    like: [],
    reviewsId: [],
    playType: 'personal',
    teacherlike: 0,
  };

  groupPlay = {
    groupPlayId: '',
    studentId: '',
    StepOneCard: '',
    StepTwoCard: '',
    StepThreeCard: '',
    dateCreated: new Date().toISOString(),
    successCard: '',
    like: [],
    reviewsId: [],
    playType: 'group',
    teacherlike: 0,
  };

  itemWord = [
    {
      title: 'mirror',
      con: {
        img: 'assets/imgs/mirror_icon.png',
        txt: '재미있는 생활을 \n 만드는 스마트 거울',
        onlyTxt: '재미있는 \n 생활을 \n 만드는 \n 스마트 거울',
        outBg: '#EA6687',
        innerBg: '#D84972',
      },
    },
    {
      title: 'speaker',
      con: {
        img: 'assets/imgs/speaker_icon.png',
        txt: '즐겁고 편리한 생활을 \n만들어주는 스마트 스피커',
        onlyTxt: '즐겁고 편리한 \n 생활을 \n 만들어주는 \n 스마트 스피커',
        outBg: '#F4B100',
        innerBg: '#F49302',
      },
    },
    {
      title: 'bicycle',
      con: {
        img: 'assets/imgs/bicycle_icon.png',
        txt: '아름다운 세상을 \n 누비는 스마트 자전거',
        onlyTxt: '아름다운 \n 세상을 누비는 \n 스마트 자전거',
        outBg: '#6D3582',
        innerBg: '#502785',
      },
    },
    {
      title: 'glasses',
      con: {
        img: 'assets/imgs/glasses_icon.png',
        txt: '보이지 않던 \n 모습까지 보여주는 스마트 안경',
        onlyTxt: '보이지 않던 \n 모습까지 \n 보여주는 \n 스마트 안경',
        outBg: '#0064B3',
        innerBg: '#003E98',
      },
    },
    {
      title: 'blackboard',
      con: {
        img: 'assets/imgs/blackboard_icon.png',
        txt: '스마트한 학교생활을 \n도와주는 스마트 칠판',
        onlyTxt: '스마트한 \n 학교생활을 \n 도와주는 \n 스마트 칠판',
        outBg: '#52BA9F',
        innerBg: '#009EA0',
      },
    },
    {
      title: 'bag',
      con: {
        img: 'assets/imgs/bag_icon.png',
        txt: '어느 곳에서나 \n함께하는 스마트 가방',
        onlyTxt: '어느곳에서나 \n 함께하는 \n 스마트 가방',
        outBg: '#C0CC38',
        innerBg: '#5DB665',
      },
    },
  ];

  futureWord = [
    { title: '얼굴인식', desc: '사람 등 생물의 생김새를 인식해요.' },
    {
      title: '생체인식',
      desc: '홍채, 지문, 얼굴과 같은 사람의 특성을 인식해요.',
    },
    { title: '사물인식', desc: '사물의 무게, 질감, 모양 등을 인식해요.' },
    { title: '모션인식', desc: '사람 혹은 물체의 움직임과 위치를 인식해요.' },
    {
      title: '환경인식',
      desc: '온도, 습도, 바람, 햇빛 등 외부 환경 조건을 인식해요.',
    },
    { title: '음성인식', desc: '사람이 말하는 음성 언어를 인식해요.' },
    { title: '검색', desc: '궁금하고 알고 싶은 정보를 찾아줘요.' },
    {
      title: '분석',
      desc: '다양한 데이터를 모으고 분류하여 의미 있는 결과를 알려줘요.',
    },
    {
      title: '예측',
      desc: '과거와 현재의 데이터를 기반으로 미래의 일을 미리 알아봐요.',
    },
    { title: '추천', desc: '사용자의 특성에 맞게 필요한 것을 알려줘요.' },
    { title: '안내', desc: '사용자가 원하는 정보를 알려줘요.' },
    { title: '경고', desc: '위험한 상황이 오지 않도록 알려줘요.' },
    {
      title: 'GPS',
      desc: '위성에서 보내는 신호를 통해 사용자의 현재 위치를 알 수 있어요.',
    },
    {
      title: '사물 인터넷',
      desc: '인터넷을 통해 모든 사물이 연결되어 있어요.',
    },
    {
      title: '홀로그램',
      desc: '빛을 이용한 기술을 통해 3차원 이미지 혹은 영상이 보여져요.',
    },
    { title: '가상현실', desc: '가상의 배경과 환경이 마치 실제처럼 펼쳐져요.' },
    {
      title: '자율주행',
      desc: '사람이 조작하지 않아도 스스로 판단하고 움직여요.',
    },
    { title: '3D프린팅', desc: '3차원의 입체적인 물건을 만들어내요.' },
  ];

  utilizationWord = [
    { topic: '교육', card: '수업', color: '#D44F7D', backcolor: '#FF78A7' },
    { topic: '교육', card: 'E 러닝', color: '#D44F7D', backcolor: '#FF78A7' },
    { topic: '교통', card: '안전', color: '#DE603E', backcolor: '#FF8666' },
    { topic: '교통', card: '도로', color: '#DE603E', backcolor: '#FF8666' },
    { topic: '교통', card: '이동수단', color: '#DE603E', backcolor: '#FF8666' },
    { topic: '교통', card: '신호', color: '#DE603E', backcolor: '#FF8666' },
    {
      topic: '스포츠',
      card: '생활체육',
      color: '#28B58D',
      backcolor: '#85DDC4',
    },
    { topic: '스포츠', card: '경기', color: '#28B58D', backcolor: '#85DDC4' },
    { topic: '의료', card: '치료', color: '#34B0B3', backcolor: '#8DDFE1' },
    { topic: '의료', card: '헬스케어', color: '#34B0B3', backcolor: '#8DDFE1' },
    { topic: '생활', card: '음식', color: '#C6C308', backcolor: '#E0DE5B' },
    { topic: '생활', card: '주거', color: '#C6C308', backcolor: '#E0DE5B' },
    { topic: '여가', card: '여행', color: '#E6A100', backcolor: '#FFC233' },
    { topic: '여가', card: '예술', color: '#E6A100', backcolor: '#FFC233' },
    { topic: '생활', card: '편의', color: '#C6C308', backcolor: '#E0DE5B' },
    { topic: '생활', card: '보안', color: '#C6C308', backcolor: '#E0DE5B' },
    { topic: '여가', card: '쇼핑', color: '#E6A100', backcolor: '#FFC233' },
    { topic: '여가', card: '미디어', color: '#E6A100', backcolor: '#FFC233' },
  ];

  itemCard;
  FutureCard;
  utiliCard;

  boardSwitch = false;

  userId;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private db: DbService,
    private auth: AuthService,
    public inputnumber: InputNumberService,
    public location: Location
  ) {
    this.userId = localStorage.getItem('userId');

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.iCardType = params.type1;
      this.fCardType = params.type2;
      this.uCardType = params.type3;
      this.gameType = params.gameType;
      this.itemCard = this.itemWord.filter(
        (elemet) => elemet.title == this.iCardType
      )[0];
      this.FutureCard = this.futureWord.filter(
        (elemet) => elemet.title == this.fCardType
      )[0];
      this.utiliCard = this.utilizationWord.filter(
        (elemet) => elemet.card == this.uCardType
      )[0];
    });
  }

  ngOnInit(): void {
    $(function () {
      console.log('to_box.val', $('.to_box').val());
      $('textarea.autosize').on('keydown keyup', function () {
        $(this)
          .height(1)
          .height($(this).prop('scrollHeight') + 12);
      });
    });
  }

  openTipPop(): any {
    const dialogRef = this.dialog.open(TipComComponent, {
      panelClass: 'tip_pop',
    });
  }

  // 나가기 얼럿
  openExitAlert(type): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { type: 'leftOk', txt: '플레이를 종료 하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data.data == 'ok') {
        this.router.navigate(['/home']);
      }
    });
  }

  // 사이드메뉴 클릭시
  boardOn(): any {
    if (this.boardSwitch == false) {
      this.boardSwitch = true;
    } else {
      this.boardSwitch = false;
    }
  }

  // span 태그 선택시
  txtPick(): any {
    if (this.pickText == false) {
      this.pickText = true;
    } else {
      this.pickText = false;
    }
  }

  // 플레이 저장 함수
  playSave(): any {
    this.inputnumber.inputnumber = 1;
    this.playing = false;

    if (this.gameType == 'personal') {
      this.personalPlay.personalPlayId = this.db.createFsId();
      this.personalPlay.studentId = this.userId;
      this.personalPlay.StepOneCard = this.itemCard;
      this.personalPlay.StepTwoCard = this.FutureCard;
      this.personalPlay.StepThreeCard = this.utiliCard;
      if (this.textAll) {
        this.personalPlay.successCard = this.textAll;
      } else {
        this.personalPlay.successCard =
          this.text1 +
          this.to1 +
          this.text2 +
          this.to2 +
          this.text3 +
          this.to3 +
          this.text4;
      }
      this.db.updateAt(
        `playUser/${this.personalPlay.personalPlayId}`,
        this.personalPlay
      );
      this.db.updateAt(`student/${this.userId}`, {
        allPlayFeed: firebase.default.firestore.FieldValue.arrayUnion(
          this.personalPlay.personalPlayId
        ),
        playGame: firebase.default.firestore.FieldValue.arrayUnion(
          this.personalPlay.personalPlayId
        ),
      });
    } else {
      this.groupPlay.groupPlayId = this.db.createFsId();
      this.groupPlay.studentId = this.userId;
      this.groupPlay.StepOneCard = this.itemCard;
      this.groupPlay.StepTwoCard = this.FutureCard;
      this.groupPlay.StepThreeCard = this.utiliCard;
      if (this.textAll) {
        this.groupPlay.successCard = this.textAll;
      } else {
        this.groupPlay.successCard =
          this.text1 +
          this.to1 +
          this.text2 +
          this.to2 +
          this.text3 +
          this.to3 +
          this.text4;
      }
      this.db.updateAt(
        `playUser/${this.groupPlay.groupPlayId}`,
        this.groupPlay
      );
      this.db.updateAt(`student/${this.userId}`, {
        allPlayFeed: firebase.default.firestore.FieldValue.arrayUnion(
          this.groupPlay.groupPlayId
        ),
        playGame: firebase.default.firestore.FieldValue.arrayUnion(
          this.groupPlay.groupPlayId
        ),
      });
    }
  }

  // 플레이 페이지 이동 함수
  goPlay() {
    this.playing = true;
    this.router.navigate(['/play-personal'], {
      queryParams: {
        gameType: this.gameType,
        only: 1,
        ing: 'end',
      },
    });
  }

  // 뒤로 이동 함수
  goBack() {
    this.router.navigate(['/play-personal'], {
      queryParams: {
        gameType: this.gameType,
        back: 'back',
      },
    });
  }

  autoGrowTextZone(e) {
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 12 + 'px';
  }

  converttext1(event) {
    this.text1 = event;
  }

  converttext2(event) {
    this.text2 = event;
  }

  converttext3(event) {
    this.text3 = event;
  }

  converttext4(event) {
    this.text4 = event;
  }
}
