<div class="wrap">
  <div class="con" *ngIf="alertData">
    <div class="txt_area">
      <p>{{ message }}</p>
      <p class="sub_message" *ngIf="subMessage">
        {{ subMessage }}
      </p>
      <div class="time_area" *ngIf="type == 'moreTime'">
        <button (click)="clickEvent(3)" [ngClass]="{ btn_ac: addTime1 }">
          03:00
        </button>
        <button (click)="clickEvent(5)" [ngClass]="{ btn_ac: addTime2 }">
          05:00
        </button>
        <button (click)="clickEvent(8)" [ngClass]="{ btn_ac: addTime3 }">
          08:00
        </button>
        <button (click)="clickEvent(10)" [ngClass]="{ btn_ac: addTime4 }">
          10:00
        </button>
        <button (click)="clickEvent(15)" [ngClass]="{ btn_ac: addTime5 }">
          15:00
        </button>
        <button (click)="clickEvent(20)" [ngClass]="{ btn_ac: addTime6 }">
          20:00
        </button>
      </div>
    </div>
    <div class="btn_wrap" *ngIf="type !== 'leftOk'">
      <button class="cancel_btn left_cancel" (click)="close('cancel')">
        {{ cancelButton ? cancelButton : "취소" }}
      </button>
      <button (click)="close('ok')">{{ okButton ? okButton : "확인" }}</button>
    </div>
    <div class="btn_wrap" *ngIf="type == 'leftOk'">
      <button (click)="close('ok')">확인</button>
      <button class="cancel_btn right_cancel" (click)="close('cancel')">
        취소
      </button>
    </div>
  </div>
</div>
