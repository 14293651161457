/** @format */

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  Router,
} from '@angular/router';
import { take } from 'rxjs/operators';
// import { AuthService } from '../services/AuthService.service';
import { AuthService } from '../services/auth.service';
import { DbService } from '../services/db.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    public db: DbService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const uid = localStorage.getItem('userId');
    const isLoggedIn = !!uid;
    if (!isLoggedIn) {
      this.router.navigate(['/play-select']);
    }
    return isLoggedIn;
  }
}
