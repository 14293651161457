import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortPipe',
})
export class SortPipe implements PipeTransform {
  constructor() {}

  // 명예의 전당 좋아요 순으로 정렬!
  transform(items: any) {
    items.map((item) => {
      item.likeCount = item.likes ? item.likes.length : 0;
      return item;
    });

    return items.sort((a, b) => {
      return a.likeCount > b.likeCount ? -1 : a.likeCount < b.likeCount ? 1 : 0;
    });
  }
}
