import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDesignComponent } from '../../alert-design/alert-design.component';

@Component({
  selector: 'header-share',
  templateUrl: './header-share.component.html',
  styleUrls: ['./header-share.component.css'],
})
export class HeaderShareComponent implements OnInit {
  public userId;
  public user$: Observable<any>;
  public student;
  public teacher;
  public whatType = '';

  public menuOn = false;

  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.userId = localStorage.getItem('userId');
    this.getData();
  }

  // 선생 or 학생 정보 불러오는 함수
  async getData() {
    this.student = this.dbService.doc$(`student/${this.userId}`);
    this.teacher = this.dbService.doc$(`teacher/${this.userId}`);
    await this.student.subscribe((data) => {
      if (data.type && !this.whatType) {
        this.whatType = 'student';
        this.user$ = this.student;
      }
    });
    await this.teacher.subscribe((data) => {
      if (data.type && !this.whatType) {
        this.whatType = 'teacher;';
        this.user$ = this.teacher;
      }
    });
  }

  ngOnInit(): void {}

  //로그아웃 하시겠습니까? 팝업
  openLogout(): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '로그아웃하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data.data == 'ok') {
        this.logout();
      }
    });
  }

  // 로그아웃 함수
  logout(): any {
    if (this.whatType == 'student') {
      localStorage.setItem('userId', '');
      localStorage.setItem('teacherCode', '');
      localStorage.setItem('userType', '');
      localStorage.clear();
      this.router.navigate(['/play-select']);
    } else {
      this.authService
        .logoutUser()
        .then((res) => {
          localStorage.clear();
          this.router.navigate(['/login']);
        })
        .catch((error) => {
          console.log('logout Error', error);
        });
    }
  }

  hamburgerMenu() {
    if (this.menuOn == false) {
      this.menuOn = true;
    } else {
      this.menuOn = false;
    }
  }
}
