<div class="select_wrap">
  <section class="top_box">
    <img src="assets/imgs/main_logo.png" />
    <h2>AI 월드에 오신 여러분 환영합니다.</h2>
    <a class="teacher_btn" (click)="goTeacherLogin()">
      <span class="dot"></span>선생님으로 로그인 하시겠습니까?
    </a>
  </section>
  <section class="bottom_box">
    <h2 class="hidden">플레이 선택하기</h2>
    <button class="single_play" (click)="personalGame()">개인플레이</button>
    <button class="group_play" (click)="groupGame()">그룹플레이</button>
  </section>
</div>
