import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'footer-share',
  templateUrl: './footer-share.component.html',
  styleUrls: ['./footer-share.component.css'],
})
export class FooterShareComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  // 개인정보 약관 페이지 이동 함수
  openPersonal(type) {
    this.router.navigateByUrl(`/terms?type=${type}`);
  }
}
