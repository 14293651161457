<div class="dialog_wrap" (click)="justClose()">
  <div class="item_card_area">
    <h2 class="hidden">물건 카드 선택</h2>
    <div
      class="item_card_box carditem1"
      (click)="openBigItemCard('mirror'); $event.stopPropagation()"
    >
      <mirror-card></mirror-card>
    </div>
    <div
      class="item_card_box carditem2"
      (click)="openBigItemCard('speaker'); $event.stopPropagation()"
    >
      <speaker-card></speaker-card>
    </div>
    <div
      class="item_card_box card_3 carditem3"
      (click)="openBigItemCard('bicycle'); $event.stopPropagation()"
    >
      <bicycle-card></bicycle-card>
    </div>
    <div
      class="item_card_box carditem4"
      (click)="openBigItemCard('glasses'); $event.stopPropagation()"
    >
      <glasses-card></glasses-card>
    </div>
    <div
      class="item_card_box carditem5"
      (click)="openBigItemCard('blackboard'); $event.stopPropagation()"
    >
      <blackboard-card></blackboard-card>
    </div>
    <div
      class="item_card_box card_3 last_card carditem6"
      (click)="openBigItemCard('bag'); $event.stopPropagation()"
    >
      <bag-card></bag-card>
    </div>
  </div>
</div>
