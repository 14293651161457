import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  RouteReuseStrategy,
  DefaultUrlSerializer,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';
import * as $ from 'jquery';

import firebase from 'firebase';
import { AuthService } from './services/auth.service';
import { DbService } from './services/db.service';
import { take } from 'rxjs/operators';

export class CustomReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  //route 를 재사용할것인가. 어디서 어디로 옮길것인지 스냅샷이 넘어온다. 주소를 따져봐서, 같으면 재사용하고, 다르면 재사용하지 않는다.
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return this.getURL(curr) === this.getURL(future);
  }

  //Detach 될때 상태를 저장할건지 아닌지 리턴해준다. detach 되는 Route를 저장해 두려면 true, 저장하지 않으려면 false
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    //나는 URL이 루트인것만  저장해 둘것이기 때문에 루트인것만 true를 준다.
    if (this.getURL(route) == '/') {
      return true;
    } else {
      return false;
    }
  }

  //위에서 저장하기로 한 스냅샷을 저장해둔다.

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    let key = this.getURL(route);
    this.handlers[key] = handle;
  }

  //저장해둔 Snapshot에 Attach 할 때의 델리게이트.
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[this.getURL(route)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) return null;
    let key = this.getURL(route);
    return this.handlers[key];
  }

  //routeSnapShot 에서 URL 을 계산해서 리턴해준다.
  getURL(route: ActivatedRouteSnapshot) {
    let next = route;
    let url = '';
    while (next) {
      if (next.url) {
        url = next.url.join('/');
      }
      next = next.firstChild;
    }
    url = '/' + url;
    return url;
  }
}

////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'ai-world';
  // userId;
  userType: string = '';

  subscription: Subscription;

  constructor(private authService: AuthService, private db: DbService) {
    // this.userId = localStorage.getItem('userId');
    this.logoutProcess();
  }

  ngOnInit() {
    window.addEventListener('beforeunload', (e) => {
      var confirmationMessage = 'o/';
      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    });

    window.onbeforeunload = () => {
      const userType: string = localStorage.getItem('userType');
      const teacherCode: string = localStorage.getItem('teacherCode');
      if (userType == 'teacher') {
        this.logoutTeacherProcess();
        this.logoutTeacher();
      }

      // student인데 그룹으로 수업을 진행했을 경우
      if (userType == 'student' && teacherCode) {
        this.logoutStudentProcess(teacherCode);
      }

      var msg = 'Are you  sure to closing this tab';
      return msg;
    };
  }

  // 자동 로그아웃
  logoutProcess(): void {
    const userType: string = localStorage.getItem('userType');
    switch (userType) {
      case 'teacher': {
        // 선생님일 경우
        // 선생님 로그아웃 및 게임 중단
        this.logoutTeacherProcess();
        this.logoutTeacher();
        break;
      }

      default: {
        // 학생 로그아웃
        this.logoutUser();
        break;
      }
    }
  }

  logoutUser(): void {
    localStorage.setItem('userType', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('teacherCode', '');
    localStorage.clear();
  }

  // 선생님의 게임 중단
  async logoutTeacherProcess(): Promise<void> {
    const myUid: string = localStorage.getItem('userId');
    this.db.updateAt(`teacher/${myUid}`, {
      playSwitch: false,
      readySwitch: false,

      groupStudent: [],
    });
  }

  async logoutStudentProcess(teacherCode: string): Promise<void> {
    this.db
      .collection$(`teacher`, (ref) =>
        ref.where('teacherCode', '==', teacherCode)
      )
      .pipe(take(1))
      .subscribe((teachers: Array<any>) => {
        if (!teachers) {
          return false;
        }
        const teacher: any = teachers[0];
        const myUid: string = localStorage.getItem('userId');
        let playSwitch: boolean = false;

        // 수업중이였으며, 현재 수업중인 학생이 1명 이상일 경우
        if (
          teacher.playSwitch &&
          teacher.groupStudent &&
          teacher.groupStudent.length > 1
        ) {
          playSwitch = true;
        }

        this.db.updateAt(`teacher/${teacher.teacherId}`, {
          groupStudent: firebase.firestore.FieldValue.arrayRemove(myUid),
          playSwitch: playSwitch,
        });
      });
  }

  logoutTeacher(): void {
    this.logoutUser();
    this.authService.logoutUser();
  }
}
