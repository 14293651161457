<div class="future_card_wrap" (click)="justClose()">
  <div
    class="card_word"
    *ngFor="let item of data"
    (click)="
      disabledChk(item.value) ? '' : openBigItemCard(item.value, data);
      $event.stopPropagation()
    "
    [ngClass]="disabledChk(item.value) ? 'dis_css' : ''"
  >
    <p>{{ item.value.title }}</p>
  </div>
</div>
