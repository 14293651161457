<main class="play_wrap">
  <sidebar-share></sidebar-share>

  <div class="write_board">
    <div class="title_area">
      <h2>STEP 4</h2>

      <button
        *ngIf="gameType == 'personal'"
        (click)="openExitAlert('exitPlay')"
      >
        나가기
        <img src="assets/imgs/exit_icon.png" alt="나가기 아이콘" />
      </button>
    </div>

    <div class="pick_card_area">
      <h2 class="hidden">선택한 카드</h2>
      <div class="card_area">
        <div class="icon_wrap">
          <img [src]="itemCard?.con.img" alt="" />
        </div>
        <div
          class="cho_card cho_card_i"
          [ngStyle]="{ background: itemCard?.con.outBg }"
        >
          <p [ngStyle]="{ background: itemCard?.con.innerBg }">
            {{ itemCard?.con.onlyTxt }}
          </p>
        </div>
        <div class="cho_card cho_card_f">
          <p>
            <strong>{{ FutureCard?.title }}</strong>
            <span>{{ FutureCard?.desc }}</span>
          </p>
        </div>
        <div
          class="cho_card cho_card_u"
          [ngStyle]="{ background: utiliCard?.backcolor }"
        >
          <p>
            <span>{{ utiliCard?.topic }}</span>
            <strong>{{ utiliCard?.card }}</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="write_wrap">
      <div class="input_box" *ngIf="to1 !== '' || to2 !== '' || to3 !== ''">
        <div class="top_input">
          <input
            type="text"
            maxlength="20"
            [(ngModel)]="text1"
            (ngModelChange)="converttext1($event)"
          />
          <input type="text" [(ngModel)]="to1" class="to_box" maxlength="3" />
          <input
            type="text"
            maxlength="10"
            [(ngModel)]="text2"
            (ngModelChange)="converttext2($event)"
          />
          <input
            type="text"
            [(ngModel)]="to2"
            class="to_box to_box2"
            maxlength="3"
          />
        </div>
        <div class="bottom_input">
          <input
            type="text"
            maxlength="20"
            [(ngModel)]="text3"
            (ngModelChange)="converttext3($event)"
          />
          <input
            type="text"
            [(ngModel)]="to3"
            class="to_box to_box3"
            maxlength="3"
          />
          <input
            type="text"
            maxlength="6"
            [(ngModel)]="text4"
            (ngModelChange)="converttext4($event)"
          />
        </div>
      </div>
      <div class="input_box2" *ngIf="to1 == '' && to2 == '' && to3 == ''">
        <textarea
          name=""
          id=""
          rows="1"
          class="autosize"
          [(ngModel)]="textAll"
          maxlength="65"
          (input)="autoGrowTextZone($event)"
        ></textarea>
      </div>
    </div>

    <!--화면 하단-->
    <div class="bottom_area">
      <button (click)="openTipPop()" class="write_tip">작성 TIP</button>

      <button
        class="save_btn"
        [disabled]="(!text1 || !text2 || !text3 || !text4) && !textAll"
        *ngIf="playing"
        (click)="playSave()"
      >
        저장하기
      </button>

      <!--저장하기 누르면 활성화-->
      <button class="save_btn" *ngIf="!playing" (click)="goPlay()">
        계속하기
      </button>

      <button class="prev_btn" (click)="goBack()">
        <img src="assets/imgs/prev_btn_b.png" alt="" />
        이전단계
      </button>
    </div>
  </div>
</main>
