// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    // apiKey: 'AIzaSyCTAT0K_HuwU3B_expAPfjrpWmAn6f0rJM',
    // authDomain: 'ai-world-185c5.firebaseapp.com',
    // databaseURL: 'https://ai-world-185c5.firebaseio.com',
    // projectId: 'ai-world-185c5',
    // storageBucket: 'ai-world-185c5.appspot.com',
    // messagingSenderId: '532554053893',
    // appId: '1:532554053893:web:658df7f89cff7a7826df6c',
    // measurementId: 'G-E9KHCHY2JP'
    apiKey: 'AIzaSyClggl0rQHoMJwPpxZ80RswO0SxurUqF9g',
    authDomain: 'ai-world-renewal.firebaseapp.com',
    databaseURL: 'https://ai-world-renewal.firebaseio.com',
    projectId: 'ai-world-renewal',
    storageBucket: 'ai-world-renewal.appspot.com',
    messagingSenderId: '139066216320',
    appId: '1:139066216320:web:9656c2b7c25731be0b7b65',
    measurementId: 'G-F73B2HG1LZ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
