<header-share></header-share>
<main id="content" class="pagnation_wrap">
  <div class="mypage_con">
    <nav class="tab_menu">
      <h2 class="hidden">마이페이지 하위 탭메뉴</h2>
      <div class="nav_area">
        <mat-button-toggle-group
          name="tabMenu"
          appearance="legacy"
          aria-label="마이페이지 메뉴"
          [(ngModel)]="myTab"
          (ngModelChange)="tabValue()"
        >
          <mat-button-toggle value="register">수업 등록하기</mat-button-toggle>
          <mat-button-toggle value="record">수업 기록하기</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="nav_txt">
        <div *ngIf="myTab == 'register'">
          <p>* 리스트를 클릭해 수업을 등록해주세요.</p>
          <p>* 수업 등록을 완료한 리스트는 배경색이 채워집니다.</p>
        </div>
        <div *ngIf="myTab == 'record'">
          <p>* 리스트를 클릭하면 수업에 대한 내용을 작성할 수 있습니다.</p>
        </div>
      </div>
      <div class="search_area">
        <h2 class="hidden">수업 검색</h2>
        <input
          type="search"
          placeholder="학교명 혹은 수업명을 입력하세요"
          maxlength="20"
          [(ngModel)]="keyWord"
        />
        <button class="search_btn">검색하기</button>
      </div>
    </nav>
  </div>

  <div class="con_wrap">
    <div *ngIf="myTab == 'register'">
      <table>
        <tr class="table_header">
          <th width="11%">번호</th>
          <th width="11%">구분</th>
          <th width="11%">수업</th>
          <th width="11%">대상</th>
          <th width="11%">학년</th>
          <th width="11%">반</th>
          <th width="11%">학생 수</th>
          <th width="11%">수업시간(분)</th>
          <th width="11%">날짜</th>
        </tr>
        <tr
          *ngFor="
            let item of finalClass$
              | async
              | paginate: { itemsPerPage: 6, currentPage: p1, id: 'register' }
              | allSearch: keyWord;
            let i = index
          "
          class="list_wrap"
          [ngClass]="{ off: item?.className ? false : true }"
          (click)="item?.className ? '' : openRegister(item?.exitId)"
        >
          <td class="first_td">{{ i + 1 }}</td>
          <td>{{ item?.type }}</td>
          <td>{{ item?.className }}</td>
          <td>{{ item?.target }}</td>
          <td>{{ item?.grade }}</td>
          <td>{{ item?.class }}</td>
          <td>{{ item?.classStuCount }}</td>
          <td>
            {{ item?.dateCreated | date: "HH:mm" }}({{
              getMinutes(item?.startCreated, item?.endCreated)
            }})
          </td>
          <td class="last_td">{{ item?.dateCreated | date: "yy.MM.dd" }}</td>
        </tr>
      </table>
      <div
        class="no_search"
        *ngIf="(finalClass$ | async | allSearch: keyWord)?.length == 0"
      >
        검색 결과가 없습니다.
      </div>
    </div>

    <div *ngIf="myTab == 'record'">
      <table>
        <tr class="table_header">
          <th width="11%">번호</th>
          <th width="11%">구분</th>
          <th width="11%">수업</th>
          <th width="11%">대상</th>
          <th width="11%">학년</th>
          <th width="11%">반</th>
          <th width="11%">학생 수</th>
          <th width="11%">수업시간(분)</th>
          <th width="11%">날짜</th>
        </tr>
        <tr
          *ngFor="
            let item of finalClass$
              | async
              | paginate: { itemsPerPage: 6, currentPage: p2, id: 'record' }
              | allSearch: keyWord;
            let i = index
          "
          class="list_wrap"
          [ngClass]="{ off: item?.classContent ? false : true }"
          (click)="openRecord(item?.exitId)"
        >
          <td class="first_td">{{ i + 1 }}</td>
          <td>{{ item?.type }}</td>
          <td>{{ item?.className }}</td>
          <td>{{ item?.target }}</td>
          <td>{{ item?.grade }}</td>
          <td>{{ item?.class }}</td>
          <td>{{ item?.classStuCount }}</td>
          <td>
            {{ item?.dateCreated | date: "HH:mm" }}({{
              getMinutes(item?.startCreated, item?.endCreated)
            }})
          </td>
          <td class="last_td">{{ item?.dateCreated | date: "yy.MM.dd" }}</td>
        </tr>
      </table>
      <div
        class="no_search"
        *ngIf="(finalClass$ | async | allSearch: keyWord)?.length == 0"
      >
        검색 결과가 없습니다.
      </div>
    </div>
  </div>
</main>

<!--페이지 네이션 영역-->
<div class="pagenation_area" *ngIf="myTab == 'register'">
  <button class="first_btn">첫번째 페이지</button>
  <pagination-controls
    (pageChange)="p1 = $event"
    screenReaderPaginationLabel="페이지 이동"
    screenReaderPageLabel="다음페이지"
    screenReaderCurrentLabel="현재페이지"
    autoHide="false"
    previousLabel=" "
    nextLabel=" "
    id="register"
  ></pagination-controls>
  <button class="last_btn">마지막 페이지</button>
</div>

<div class="pagenation_area" *ngIf="myTab == 'record'">
  <button class="first_btn">첫번째 페이지</button>
  <pagination-controls
    (pageChange)="p2 = $event"
    pagination-controls
    screenReaderPaginationLabel="페이지 이동"
    screenReaderPageLabel="다음페이지"
    screenReaderCurrentLabel="현재페이지"
    autoHide="false"
    previousLabel=" "
    nextLabel=" "
    id="record"
  ></pagination-controls>
  <button class="last_btn">마지막 페이지</button>
</div>
<footer-share></footer-share>
