<header-share></header-share>
<main class="wrap">
    <div class="con" *ngIf="term$ | async as term">
        <h2 *ngIf="type == 'serviceTerm'">서비스 이용약관</h2>
        <h2 *ngIf="type == 'privacy'">개인정보수집</h2>

        <div class="txt_box">{{term[type]}}</div>

    </div>
</main>
<footer-share></footer-share>