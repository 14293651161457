/** @format */

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentChangeAction,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { Observable, from, combineLatest, defer, of, pipe } from 'rxjs';
import {
  map,
  tap,
  take,
  switchMap,
  mergeMap,
  expand,
  takeWhile,
} from 'rxjs/operators';
import * as firebase from 'firebase/app';

export const docJoin = (
  afs: AngularFirestore,
  paths: { [key: string]: string }
) => {
  return (source) =>
    defer(() => {
      let parent;
      const keys = Object.keys(paths);

      return source.pipe(
        switchMap((data) => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          const docs$ = keys.map((k) => {
            const fullPath = `${paths[k]}/${parent[k]}`;
            return afs.doc(fullPath).valueChanges();
          });

          // return combineLatest, it waits for all reads to finish
          return combineLatest(docs$);
        }),
        map((arr) => {
          // We now have all the associated douments
          // Reduce them to a single object based on the parent's keys
          const joins = keys.reduce((acc, cur, idx) => {
            return { ...acc, [cur]: arr[idx] };
          }, {});

          // Return the parent doc with the joined objects
          return { ...parent, ...joins };
        })
      );
    });
};

export const leftJoinQuery = (
  afs: AngularFirestore,
  field,
  collection,
  limit = 100
) => {
  return source =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;
          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array
            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = ref => ref.where(field, '==', doc[field]);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap(final => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};

export const leftJoin = (
  afs: AngularFirestore,
  field,
  collection,
  limit = 100
) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {

          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref.where(field, '==', doc[field]);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap((final) => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};

export const leftJoin2 = (afs: AngularFirestore, collection) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc['id']) {
              // Perform query on join key, with optional limit
              const q = (ref) => ref.where('divisionId', '==', doc['id']);

              reads$.push(
                afs
                  .collection(collection, q)
                  .snapshotChanges()
                  .pipe(
                    map((actions) => {
                      return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      });
                    })
                  )
              );
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap((final) => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};

export const leftJoinDocument = (afs: AngularFirestore, field, collection) => {
  return source =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;
          cache.clear();

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            // Skip if doc field does not exist or is already in cache
            if (!doc[field] || cache.get(doc[field])) {
              continue;
            }

            // Push doc read to Array
            reads$.push(afs.collection(collection).doc(doc[field]).valueChanges());
            cache.set(doc[field], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v[field]);
            return {
              ...v,
              [field]: { ...joins[joinIdx], teacherCode: v[field] } || null,
            };
          });
        }),
        tap(final => console.log(`Queried ${(final as any).length}, Joined ${cache.size} docs`))
      );
    });
};

export const colletionJoin = (
  afs: AngularFirestore,
  category,
  collection,
  field,
  limit
) => {
  return (source) =>
    defer(() => {
      // Operator state
      let array;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((select) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          array = category as any[];

          const reads$ = [];
          for (const doc of array) {
            // Push doc read to Array
            console.log('doc', select, field, doc);
            if (doc) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref
                  .where('genre', '==', select)
                  .where(field, 'array-contains', doc)
                  .orderBy('totalLike', 'desc')
                  .limit(limit);
              const collectionMap = pipe(
                map((docs: QuerySnapshot<any>) => {
                  return docs.docs.map((e) => {
                    return {
                      id: e.id,
                      ...e.data(),
                    } as any;
                  });
                })
              );
              reads$.push(
                afs
                  .collection(collection, q)
                  .snapshotChanges()
                  .pipe(
                    map((actions) => {
                      return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      });
                    })
                  )
              );
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return array.map((v, i) => {
            totalJoins += joins[i].length;
            return { ['name']: v, ['photos']: joins[i] || null };
          });
        }),
        tap((final) => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};

export interface Post {
  userId: string;
  createdAt: Date;
  image: string;
  content: string;
  likeCount: number;
  [key: string]: any;
}

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class DbService {
  public postsRef: AngularFirestoreCollection<Post>;
  public masterRef;
  constructor(public afs: AngularFirestore) {
    this.masterRef = this.afs.doc(`master/${'1Xod9dBvZkUHJDPSTakjsoR6CCs2'}`);
  }

  createFsId(): any {
    return this.afs.createId();
  }

  createdAt(): any {
    return firebase.default.firestore.FieldValue.serverTimestamp();
  }

  collection$(path, query?): any {
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  collectionOb$(path, query?): any {
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data: any = actions[0].payload.doc.data();
          return { ...data }
        })
      );
  }

  doc$(path): Observable<any> {
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          const data: any = doc.payload.data();
          const id = doc.payload.id;
          return { id: doc.payload.id, ...data };
        })
      );
  }

  docOne$(path): Observable<any> {
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          const data: any = doc.payload.data();
          const id = doc.payload.id;
          return { ...data };
        })
      );
  }

  doc2$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map(
          (
            doc: Action<
              DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>
            >
          ) => {
            return doc.payload.data() as T;
          }
        )
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs: DocumentChangeAction<T>[]) => {
          return docs.map((a: DocumentChangeAction<T>) =>
            a.payload.doc.data()
          ) as T[];
        })
      );
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   **/

  // ** 기본적인 DB처리 **//
  // tslint:disable-next-line:ban-types
  updateAt(path: string, data: Object): Promise<any> {
    const segments = path.split('/').filter((v) => v);
    if (segments.length % 2) {
      // Odd is always a collection
      return this.afs.collection(path).add(data);
    } else {
      // Even is always document
      return this.afs.doc(path).set(data, { merge: true });
    }
  }

  delete(path): any {
    return this.afs.doc(path).delete();
  }

  /**
   * @param  {string} path path to document
   *
   * Deletes document from Firestore
   **/

  deleteDoc(path: string): Promise<any> {
    return this.afs.doc(path).delete();
  }

  acceptOrder(obj): any {
    return new Promise((resolve, reject) => {
      this.connectOrder(obj)
        .then((success) => {
          const ref = this.afs.doc(`order/${obj.orderId}`);
          return ref
            .update({
              connectCompanys: firebase.default.firestore.FieldValue.arrayUnion(obj.companyId),
            })
            .then((success) => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
              console.log('error', error);
            });
        })
        .catch((error) => {
          reject(false);
          console.log('error', error);
        });
    });
  }

  acceptagOrder(obj): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`arrangeorder/${obj.orderId}`);
      return ref
        .update({ connectCompany: obj.companyId })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
          console.log('error', error);
        });
    });
  }

  connectOrder(obj): any {
    return new Promise((resolve, reject) => {
      return this.updateAt(`connect`, obj)
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
          console.log('error', error);
        });
    });
  }

  payVideoOrder(orderId, companyId): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`order/${orderId}`);
      return ref
        .update({ paidCompanys: firebase.default.firestore.FieldValue.arrayUnion(companyId) })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
          console.log('error', error);
        });
    });
  }

  refundOrder(orderId, companyId): any {
    return new Promise((resolve, reject) => {
      firebase.default.firestore().runTransaction((transaction) => {
        return transaction
          .get(firebase.default.firestore().collection('order').doc(orderId))
          .then((docData) => {
            const refundCompanys = docData.data().refundCompanys || {};
            refundCompanys[companyId] = true;
            return transaction.set(
              firebase.default.firestore().collection('order').doc(orderId),
              { refundCompanys },
              { merge: true }
            );
          })
          .then((success) => {
            resolve(true);
          })
          .catch((error) => {
            reject(false);
            console.log('error', error);
          });
      });
    });
  }

  refundagOrder(orderId, companyId): any {
    return new Promise((resolve, reject) => {
      firebase.default.firestore().runTransaction((transaction) => {
        return transaction
          .get(firebase.default.firestore().collection('arrangeorder').doc(orderId))
          .then((docData) => {
            const refundCompanys = docData.data().refundCompanys || {};
            refundCompanys[companyId] = true;
            return transaction.set(
              firebase.default.firestore().collection('arrangeorder').doc(orderId),
              { refundCompanys },
              { merge: true }
            );
          })
          .then((success) => {
            resolve(true);
          })
          .catch((error) => {
            reject(false);
            console.log('error', error);
          });
      });
    });
  }

  checkUsername(nickName: string): any {
    nickName = nickName.toLowerCase();
    return this.doc$(`nickNames/${nickName}`);
  }

  appService(): any {
    const id = 'bfHp2x2Xf9CvpqmZZFvD';
    return this.doc$(`appService/${id}`).pipe(take(1)).toPromise();
  }

  likeInfo(infoId, userId): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({ likes: firebase.default.firestore.FieldValue.arrayUnion(userId) })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
          console.log('error', error);
        });
    });
  }

  dislikeInfo(infoId, userId): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({ likes: firebase.default.firestore.FieldValue.arrayRemove(userId) })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
          console.log('error', error);
        });
    });
  }

  archiveInfo(infoId, userId): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({ archive: firebase.default.firestore.FieldValue.arrayUnion(userId) })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  viewInfo(infoId, userId): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({ view: firebase.default.firestore.FieldValue.arrayUnion(userId) })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  deleteArchive(infoId, userId): any {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({ archive: firebase.default.firestore.FieldValue.arrayRemove(userId) })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }
}
