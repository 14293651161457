<div class="pop_wrap">
  <div class="top_box">
    <h2>수업등록하기</h2>
    <button (click)="close()">
      <img src="assets/imgs/close_g_icon.png" alt="" />
    </button>
  </div>

  <div class="info">
    <form class="form_wrap">
      <fieldset>
        <legend class="hidden">수업등록하기</legend>
        <div class="input_area select_area">
          <p class="input_wrap">
            <label for="type1">구분</label>
            <select
              name="type1"
              id="type1"
              class="type1"
              [(ngModel)]="type1"
              [ngClass]="{ placeholder: type1 == 'placeholder' }"
            >
              <option value="placeholder" disabled selected hidden>
                수업의 종류를 선택하세요.
              </option>
              <option value="학교수업">학교수업</option>
              <option value="프로그램">프로그램</option>
              <option value="특강">특강</option>
              <option value="센터">센터</option>
              <option value="자유학년제">자유학년제</option>
              <option value="특활">특활</option>
              <option value="동아리">동아리</option>
              <option value="창체">창체</option>
              <option value="기타">기타</option>
            </select>
            <input
              type="text"
              *ngIf="type1 == '기타'"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="etcType"
              class="more_write"
            />
          </p>
          <p class="vdt" *ngIf="type1 == 'placeholder'">
            수업의 종류를 선택하세요
          </p>
        </div>

        <div class="input_area">
          <p class="input_wrap">
            <label for="lecture">수업</label>
            <input
              type="text"
              name="lecture"
              id="lecture"
              maxlength="20"
              class="lecture"
              [(ngModel)]="lecture"
              placeholder="학교명이나 수업명을 적어주세요"
            />
          </p>
          <p class="vdt" *ngIf="lecture?.length == 0">
            학교명이나 수업명을 적어주세요.
          </p>
        </div>

        <div class="input_area select_area">
          <p class="input_wrap">
            <label for="object">대상</label>
            <select
              name="object"
              id="object"
              class="object"
              [(ngModel)]="object"
              [ngClass]="{ placeholder: object == 'placeholder' }"
            >
              <option value="placeholder" disabled selected hidden>
                대상을 선택하세요.
              </option>
              <option value="초등">초등</option>
              <option value="중등">중등</option>
              <option value="고등">고등</option>
              <option value="기타">기타</option>
            </select>
            <input
              type="text"
              *ngIf="object == '기타'"
              [(ngModel)]="etcObject"
              [ngModelOptions]="{ standalone: true }"
              class="more_write"
            />
          </p>
          <p class="vdt" *ngIf="object == 'placeholder'">
            수업의 대상을 선택하세요
          </p>
        </div>

        <div class="input_area">
          <div class="input_wrap radio_box">
            <p class="radio_title">학년</p>
            <div class="radio_area">
              <input
                (click)="gradeSelect($event)"
                type="radio"
                name="step"
                value="1"
                id="a"
              />
              <label for="a" class="radio_label"><span>1</span></label>
              <input
                (click)="gradeSelect($event)"
                type="radio"
                name="step"
                value="2"
                id="b"
              />
              <label for="b" class="radio_label"><span>2</span></label>
              <input
                (click)="gradeSelect($event)"
                type="radio"
                name="step"
                value="3"
                id="c"
              />
              <label for="c" class="radio_label"><span>3</span></label>
              <input
                (click)="gradeSelect($event)"
                type="radio"
                name="step"
                value="4"
                id="d"
              />
              <label for="d" class="radio_label"><span>4</span></label>
              <input
                (click)="gradeSelect($event)"
                type="radio"
                name="step"
                value="5"
                id="e"
              />
              <label for="e" class="radio_label"><span>5</span></label>
              <input
                (click)="gradeSelect($event)"
                type="radio"
                name="step"
                value="6"
                id="f"
              />
              <label for="f" class="radio_label"><span>6</span></label>
            </div>
          </div>
          <p class="vdt" *ngIf="!grade">학년을 선택하세요.</p>
        </div>

        <div class="input_area">
          <div class="input_wrap radio_box">
            <p class="radio_title">반</p>
            <div class="radio_area">
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="1"
                id="class1"
              />
              <label for="class1" class="radio_label"><span>1</span></label>
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="2"
                id="class2"
              />
              <label for="class2" class="radio_label"><span>2</span></label>
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="3"
                id="class3"
              />
              <label for="class3" class="radio_label"><span>3</span></label>
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="4"
                id="class4"
              />
              <label for="class4" class="radio_label"><span>4</span></label>
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="5"
                id="class5"
              />
              <label for="class5" class="radio_label"><span>5</span></label>
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="6"
                id="class6"
              />
              <label for="class6" class="radio_label"><span>6</span></label>
              <input
                (click)="classSelect($event)"
                type="radio"
                name="class"
                value="7"
                id="class7"
              />
              <label for="class7" class="radio_label radio_etc"
                ><span>기타</span></label
              >
              <input
                type="text"
                name="etcClass"
                [(ngModel)]="etcClass"
                class="more_write white_etc"
              />
            </div>
          </div>
          <p class="vdt" *ngIf="!class">반을 선택하세요.</p>
        </div>

        <div class="info_wrap" *ngIf="exit$ | async as exit">
          <div class="info_area">
            <span class="info_title">학생 수</span>
            <span class="notice_area"> {{ exit?.classStuCount }}명 </span>
          </div>
          <div class="info_area">
            <span class="info_title">수업시간</span>

            <span class="notice_area">
              {{ exit?.startCreated | date: "HH:mm" }}
            </span>
          </div>
          <div class="info_area">
            <span class="info_title">진행시간</span>
            <span class="notice_area">
              {{ getMinutes(exit?.startCreated, exit?.endCreated) }}분
            </span>
          </div>
          <div class="info_area last_info">
            <span class="info_title">날짜</span>
            <span class="notice_area">
              {{ exit?.dateCreated | date: "yy.MM.dd" }}
            </span>
          </div>
        </div>
      </fieldset>
    </form>

    <button
      class="register_btn"
      [disabled]="
        type1 == 'placeholder' ||
        lecture.length == 0 ||
        object == 'placeholder' ||
        !class ||
        !grade
      "
      (click)="finalRegister()"
    >
      등록하기
    </button>
  </div>
</div>
