import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ItemCardBigComponent } from '../item-card-big/item-card-big.component';
import * as $ from 'jquery';
import { DbService } from 'src/app/services/db.service';
import { switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
@Component({
  selector: 'app-utilization-card',
  templateUrl: './utilization-card.component.html',
  styleUrls: ['./utilization-card.component.css'],
})
export class UtilizationCardComponent implements OnInit {
  public userId;
  public playCards;

  public selectedStepCards: any = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UtilizationCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: DbService
  ) {
    this.userId = localStorage.getItem('userId');

    this.playCards = this.dbService
      .collection$(`student`, (ref) =>
        ref.where('studendId', '==', this.userId)
      )
      .pipe(
        switchMap((playCard: any) => {
          if (playCard.length > 0) {
            let cards$ = [];
            const cards = playCard[0].playGame;

            cards.forEach((cardInfo) => {
              let tmp$ = this.dbService.doc$(`playUser/${cardInfo}`);
              cards$.unshift(tmp$);
            });

            if (cards$.length > 0) {
              return combineLatest(cards$);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.playCards.subscribe((Data) => {
      if (Data && Data.length > 0) {
        Data.forEach((element) => {
          if (element.StepThreeCard) {
            this.selectedStepCards.push(element.StepThreeCard.card);
          }
        });
      }
    });
  }

  disabledChk(value) {
    const val = value.card;
    if (this.selectedStepCards.indexOf(val) > -1) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    let dataI = this.data.length;

    $(function () {
      for (let i = 1; i <= dataI; i++) {
        // console.log('dataI', i);
        if (i == 1) {
          // console.log('i', i);
          $('.card_word:first-child').animate(
            {
              left: '0%',
              top: 0,
              opacity: 1,
            },
            200
          );
        } else if (i > 1 && i <= 6) {
          // console.log('2', i);
          //옆으로 똑같이
          $('.card_word:nth-child(' + i + ')')
            // .delay(200)
            .animate(
              {
                left: (i - 1) * 17 + '%',
                opacity: 1,
                top: '0%',
              },
              200 * i,
              'swing'
            );
        } else if (i >= 7 && i <= 12) {
          // console.log('3', i);
          //밑으로 한번 옆으로 똑같이
          $('.card_word:nth-child(' + i + ')')
            .css({ top: '15.63rem', opacity: 1 })
            .animate(
              {
                left: (i - 7) * 17 + '%',
              },
              200 * (i - 6),
              'swing'
            );
        } else {
          // console.log('4', i);
          $('.card_word:nth-child(' + i + ')')
            .css({ top: '31.26rem', opacity: 1 })
            .animate(
              {
                left: (i - 13) * 17 + '%',
              },
              200 * (i - 12),
              'swing'
            );
        }
      }
    });
  }

  openBigItemCard(item, allitem): any {
    console.log('ITEM', item);
    this.dialogRef.close({
      data: item,
      type: 'uCard',
      alldata: allitem,
    });
  }

  justClose(): any {
    this.dialogRef.close();
  }
}
