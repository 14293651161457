import { map, take } from 'rxjs/operators';
import { DbService } from 'src/app/services/db.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDesignJustWordComponent } from '../alert-design-just-word/alert-design-just-word.component';
import { ChatBoxComponent } from '../template/chat-box/chat-box.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase/app';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-play-group-all',
  templateUrl: './play-group-all.component.html',
  styleUrls: ['./play-group-all.component.css'],
})
export class PlayGroupAllComponent implements OnInit {
  boardSwitch = false;
  wlike = false;

  userId;
  student$;
  constructor(
    public dialog: MatDialog,
    public db: DbService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userId = localStorage.getItem('userId');

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.student$ = this.db.doc$(`groupFeed/${params.teacherCode}`);
    });
  }

  ngOnInit(): void {}

  // 게시판 On/Off 함수
  boardOn(): any {
    if (this.boardSwitch == false) {
      this.boardSwitch = true;
    } else {
      this.boardSwitch = false;
    }
  }

  // 댓글 창 오픈 함수
  openComment(code) {
    const dialogRef = this.dialog.open(ChatBoxComponent, {
      panelClass: 'chat_box',
      data: {
        feedCode: code,
      },
    });
  }

  // 플레이 종료 창 오픈 함수
  endGame(): any {
    const dialogRef = this.dialog.open(AlertDesignJustWordComponent, {
      panelClass: 'word_pop',
      data: { txt: '플레이가 종료되었습니다.' },
    });
  }

  // 플레이 시간 종료 창 오픈 함수
  timeOver(): any {
    const dialogRef = this.dialog.open(AlertDesignJustWordComponent, {
      panelClass: 'word_pop',
      data: { txt: '플레이 시간이 종료되었습니다.' },
    });
  }

  // 좋아요 업데이트 함수
  ilike(data, alllike) {
    const index = alllike.indexOf(this.userId);
    if (index > -1) {
      this.db.updateAt(`playUser/${data}`, {
        like: firebase.default.firestore.FieldValue.arrayRemove(this.userId),
      });
    } else {
      this.db.updateAt(`playUser/${data}`, {
        like: firebase.default.firestore.FieldValue.arrayUnion(this.userId),
      });
    }
  }

  // 좋아요 눌렀는지 확인 function
  likeTF(all) {
    const index = all.indexOf(this.userId);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  // 피드 가져와서 local에 저장
  downloadImg(feedCard: string): void {
    // feedCard html 아이디 가져와서 이미지 다운로드
    var node = document.getElementById(feedCard);

    htmlToImage
      .toPng(node)
      .then((dataUrl: string) => {
        let link = document.createElement('a');
        link.download = 'feed';
        link.href = dataUrl;
        link.click();
      })
      .catch((error: Error) => {
        console.error('oops, something went wrong!', error);
      });
  }
}
