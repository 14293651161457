import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of, combineLatest } from 'rxjs';
import { delay } from 'rxjs/internal/operators/delay';


@Injectable({
    providedIn: 'root',
})

export class UserInfoService {

    public userInfo = new BehaviorSubject('');
    public cardFeedInfo1 = new BehaviorSubject('');
    public cardFeedInfo2 = new BehaviorSubject('');
    public cardFeedInfo3 = new BehaviorSubject('');

    constructor() {
    }

    getUserInfo(): Observable<any> {
        return this.userInfo
    }

    updateUserInfo(info: any) {
        this.userInfo.next(info);
    }

    //////// 이전 카드 정보 불러오기 ////////
    getBeforeCardInfo(): Observable<any> {
        return combineLatest(this.cardFeedInfo1, this.cardFeedInfo2, this.cardFeedInfo3)
    }

    updateCardInfo(number, card: any) {
        if (number == '1') {
            this.cardFeedInfo1.next(card);
        } else if (number == '2') {
            this.cardFeedInfo2.next(card);
        } else if (number == '3') {
            this.cardFeedInfo3.next(card);
        }
    }

}



