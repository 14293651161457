import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchTerm: string) {
    if (searchTerm) {
      // 자주하는 질문, 공지사항 검색 keyword
      return items.filter(
        (keyword) => keyword.title && keyword.title.indexOf(searchTerm) > -1
      );
    } else {
      return items;
    }
  }
}
