import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'alert-design',
  templateUrl: './alert-design.component.html',
  styleUrls: ['./alert-design.component.css'],
})
export class AlertDesignComponent implements OnInit {
  type;
  message;
  alertData;
  subMessage;
  okButton;
  cancelButton;
  moreTime;

  removeFeedKey;
  uploadFeedKey;

  selectFeed;

  addTime1 = false;
  addTime2 = false;
  addTime3 = false;
  addTime4 = false;
  addTime5 = false;
  addTime6 = false;

  constructor(
    public dialogRef: MatDialogRef<AlertDesignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // 기본 데이터
    this.alertData = data;
    // 버튼 디자인 다를때 TYPE값 없을수도 있음
    this.type = data.type;
    //alert 메세지
    this.message = data.txt;
    //()안에 있는 얇은 메세지 없을수도 있음
    this.subMessage = data.sub;
    //버튼 text 데이터값 없을수도 있음
    this.okButton = data.ok;
    this.cancelButton = data.cancel;
  }

  ngOnInit(): void {}

  // 분석택 (data ==> 분)
  clickEvent(data) {
    this.moreTime = data;

    if (data == 3) {
      this.addTime2 = false;
      this.addTime3 = false;
      this.addTime4 = false;
      this.addTime5 = false;
      this.addTime6 = false;
      return (this.addTime1 = true);
    } else if (data == 5) {
      this.addTime1 = false;
      this.addTime3 = false;
      this.addTime4 = false;
      this.addTime5 = false;
      this.addTime6 = false;
      return (this.addTime2 = true);
    } else if (data == 8) {
      this.addTime1 = false;
      this.addTime2 = false;
      this.addTime4 = false;
      this.addTime5 = false;
      this.addTime6 = false;
      return (this.addTime3 = true);
    } else if (data == 10) {
      this.addTime1 = false;
      this.addTime2 = false;
      this.addTime3 = false;
      this.addTime5 = false;
      this.addTime6 = false;
      return (this.addTime4 = true);
    } else if (data == 15) {
      this.addTime1 = false;
      this.addTime2 = false;
      this.addTime3 = false;
      this.addTime4 = false;
      this.addTime6 = false;
      return (this.addTime5 = true);
    } else if (data == 20) {
      this.addTime1 = false;
      this.addTime2 = false;
      this.addTime3 = false;
      this.addTime4 = false;
      this.addTime5 = false;
      return (this.addTime6 = true);
    }
  }

  // 창 닫기 함수
  close(type): void {
    this.dialogRef.close({
      data: type,
      addTime: this.moreTime,
    });
  }
}
