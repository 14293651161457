<header-share></header-share>
<main
  id="content"
  [ngClass]="{ pagnation_wrap: myTab == 'faq' || myTab == 'notice' }"
>
  <div class="mypage_con">
    <nav class="tab_menu">
      <h2 class="hidden">마이페이지 하위 탭메뉴</h2>
      <div class="nav_area">
        <mat-button-toggle-group
          name="tabMenu"
          appearance="legacy"
          aria-label="마이페이지 메뉴"
          [(ngModel)]="myTab"
        >
          <mat-button-toggle value="faq">자주하는 질문</mat-button-toggle>
          <mat-button-toggle value="notice">공지사항</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="search_area" *ngIf="myTab == 'faq'">
        <h2 class="hidden">자주하는 질문 검색</h2>
        <input
          type="search"
          placeholder="검색어를 입력하세요."
          maxlength="20"
          [(ngModel)]="searchKeyword"
        />
        <button class="search_btn" (click)="searchFaq()">검색하기</button>
      </div>
    </nav>
  </div>

  <div class="con_wrap">
    <section class="faq" *ngIf="myTab == 'faq'">
      <h2 class="hidden">자주하는 질문 글 목록</h2>
      <div *ngIf="faqs$ | async as faqs">
        <div class="list_area">
          <mat-accordion
            class="example-headers-align"
            *ngFor="
              let item of faqs
                | search: searchKeyword
                | paginate: { itemsPerPage: 4, currentPage: p1, id: 'faq' };
              let i = index
            "
          >
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> {{ item.title }} </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ item.content }}</p>
            </mat-expansion-panel>
          </mat-accordion>

          <div
            class="no_search"
            *ngIf="
              faqs.length == 0 || (faqs | search: searchKeyword)?.length == 0
            "
          >
            검색 결과가 없습니다.
          </div>
        </div>
      </div>
    </section>
    <section class="faq" *ngIf="myTab == 'notice'">
      <h2 class="hidden">공지사항</h2>
      <div class="list_area">
        <mat-accordion
          class="example-headers-align"
          *ngFor="
            let item of notices$
              | async
              | search: searchKeyword
              | paginate: { itemsPerPage: 4, currentPage: p2, id: 'notice' };
            let i = index
          "
        >
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                ><span class="title_num">{{ i + 1 }}</span> {{ item.title }}
                <span class="notice_date">{{
                  item.dateCreated | date: "yy-MM-dd"
                }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.content }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </section>
  </div>
</main>

<div class="pagenation_area" *ngIf="myTab == 'faq'">
  <button class="first_btn" [disabled]="p1 == 1" (click)="firstPage()">
    첫번째 페이지
  </button>
  <pagination-controls
    (pageChange)="p1 = $event"
    screenReaderPaginationLabel="페이지 이동"
    screenReaderPageLabel="다음페이지"
    screenReaderCurrentLabel="현재페이지"
    autoHide="false"
    previousLabel=" "
    nextLabel=" "
    responsive="true"
    id="faq"
  ></pagination-controls>
  <button
    class="last_btn"
    [disabled]="p1 >= list.length / 4"
    (click)="lastPage()"
  >
    마지막 페이지
  </button>
</div>

<div class="pagenation_area" *ngIf="myTab == 'notice'">
  <button class="first_btn" disabled="false">첫번째 페이지</button>
  <pagination-controls
    (pageChange)="p2 = $event"
    pagination-controls
    screenReaderPaginationLabel="페이지 이동"
    screenReaderPageLabel="다음페이지"
    screenReaderCurrentLabel="현재페이지"
    autoHide="false"
    previousLabel=" "
    nextLabel=" "
    responsive="true"
    id="notice"
  ></pagination-controls>
  <button class="last_btn">마지막 페이지</button>
</div>
<footer-share></footer-share>
