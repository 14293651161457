import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';

@Component({
  selector: 'app-card-board',
  templateUrl: './card-board.component.html',
  styleUrls: ['./card-board.component.css'],
})
export class CardBoardComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CardBoardComponent>
  ) {}

  ngOnInit(): void {
    $(function () {
      // for (let i = 1; i <= 6; i++) {
      //   $('.item_card_box:nth-child(' + i + ')').animate({
      //   });
      // }

      $('.carditem1')
        .animate(
          {
            top: 0,
            left: 0,
          },
          200
        )
        .css({ transform: 'translate(0, 0)' }),
        $('.carditem2')
          .animate(
            {
              top: 0,
              left: 16.96 + 3.917 + 'rem',
            },
            200
          )
          .css({ transform: 'translate(0, 0)' }),
        $('.carditem3')
          .animate(
            {
              top: 0,
              left: 16.96 + 16.96 + 3.917 + 3.917 + 'rem',
            },
            200
          )
          .css({ transform: 'translate(0, 0)' }),
        $('.carditem4')
          .animate(
            {
              bottom: 0,
              left: 0,
            },
            200
          )
          .css({ transform: 'translate(0, 0)' }),
        $('.carditem5')
          .animate(
            {
              bottom: 0,
              left: 16.96 + 3.917 + 'rem',
            },
            200
          )
          .css({ transform: 'translate(0, 0)' }),
        $('.carditem6')
          .animate(
            {
              bottom: 0,
              left: 16.96 + 3.917 + 16.96 + 3.917 + 'rem',
            },
            200
          )
          .css({ transform: 'translate(0, 0)' });
    });
  }

  openBigItemCard(card): any {
    this.dialogRef.close({ type: 'item', card });
  }

  justClose(): any {
    this.dialogRef.close();
  }
}
