<mat-progress-bar value="100" mode="determinate"></mat-progress-bar>

<div class="div_wrapper">
  <h2>이름 등록</h2>
  <div class="title">자신의 이름을 입력하고 플레이를 시작하세요.</div>

  <div class="content">
    <input type="text" placeholder="이름을 입력해 주세요." [(ngModel)]="name" />
  </div>

  <p class="warning" *ngIf="noDataSwitch">이미 사용중인 이름입니다.</p>

  <button class="save_btn" [disabled]="confirmName" (click)="start()">
    AI 월드 시작
  </button>
</div>
