<div class="tip_wrap">
  <div class="title_area">
    <h2>
      <img
        src="assets/imgs/qmark_icon.png"
        alt="물음표 아이콘"
        class="q_mark"
      />TIP / 도움
    </h2>
    <button class="x_btn" (click)="popClose()">
      <img src="assets/imgs/close_b.png" alt="닫기버튼" />
    </button>
  </div>

  <section class="tip_txt_box">
    <h3>1) 빈 칸을 완성해 나만의 스마트 아이템을 설명해보세요!</h3>
    <ul class="top_ul">
      <li>
        <em class="li_line">사용자의 음악 감상 기록</em> 을
        <em class="li_line">분석</em> 하여
        <em class="li_line">취향에 어울리는 이색장소를 추천</em>하는
        <em class="li_line">스마트 스피커</em>
      </li>
      <li>
        <em class="li_line">날씨에 따라 적절한 코스</em> 를
        <em class="li_line">추천</em>하여
        <em class="li_line">여행길을 안내</em> 하는
        <em class="li_line">스마트 자전거</em>
      </li>
      <li>
        <em class="li_line">사람의 시선</em> 을
        <em class="li_line">인식</em> 하여
        <em class="li_line">칠판에 보이는 내용을 음성으로 듣게</em> 하는
        <em class="li_line">스마트 칠판</em>
      </li>
    </ul>

    <h3>2) 나만의 스마트 아이템을 자유롭게 설명해보세요!</h3>
    <ul class="bottom_ul">
      <li>
        공부를 하다가 버튼을 누르면 관련된 정보를 검색하고 그 설명이 내 눈앞에
        나타나는 스마트 안경
      </li>
      <li>
        거울 앞에서 운동을 할 때 나의 동작을 거울이 인식해서 운동 종목에 따라
        적절한 배경을 가상화면으로 비춰주는 스마트 거울
      </li>
      <li>
        가방의 표면이 홀로그램으로 변해 내가 이동하는 장소에 따라 어울리는
        디자인으로 바뀌는 스마트 여행 가방
      </li>
    </ul>
  </section>
  <p class="last_notice">
    <strong>
      내가 만드는 스마트 아이템을 통해 새롭고 다양한 아이디어를 펼쳐보세요!
    </strong>
  </p>
</div>
