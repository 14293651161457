import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.css'],
})
export class InputCodeComponent implements OnInit {
  public code: string = '';

  public noDataSwitch: boolean = false;

  constructor(private router: Router, private db: DbService) {}

  ngOnInit(): void {}

  // 선생님 코드가 정확히 입력 되었다면
  // input-name으로 이동
  async goInputName(): Promise<void | boolean> {
    try {
      await this.checkCode();
      this.noDataSwitch = false;
      this.router.navigate(['/input-name'], {
        queryParams: {
          code: this.code,
          gameType: 'group',
        },
      });
    } catch (err) {
      // 선생님 코드 없음
      this.noDataSwitch = true;
      this.code = '';
    }
  }

  // 선생님 코드가 유효한지 체크
  async checkCode(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db
        .collection$(`teacher`, (ref) =>
          ref.where('teacherCode', '==', this.code)
        )
        .pipe(take(1))
        .subscribe((datas) => {
          if (datas.length == 0) {
            reject('no data');
          } else {
            resolve('ok');
          }
        });
    });
  }
}
