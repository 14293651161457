<mat-progress-bar value="50" mode="determinate"></mat-progress-bar>

<div class="div_wrapper">
  <h2>수업 코드 입력</h2>
  <div class="title">수업 신청 시 발급된 수업코드를 입력해 주세요.</div>

  <div class="content">
    <input
      type="text"
      placeholder="코드를 입력해 주세요."
      [(ngModel)]="code"
      maxlength="5"
    />
  </div>

  <p class="warning" *ngIf="noDataSwitch">코드를 다시 확인해주세요.</p>

  <button class="save_btn" [disabled]="!code" (click)="goInputName()">
    다음
  </button>
</div>
