import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-design-onebutton',
  templateUrl: './alert-design-onebutton.component.html',
  styleUrls: ['./alert-design-onebutton.component.css'],
})
export class AlertDesignOnebuttonComponent implements OnInit {
  type;
  message;
  alertData;
  button;
  constructor(
    public dialogRef: MatDialogRef<AlertDesignOnebuttonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.alertData = data;
    this.type = data.type;
    this.message = data.txt;
    this.button = data.button;
  }

  ngOnInit(): void {}

  // 창 닫기 함수
  close(type): void {
    this.dialogRef.close({
      data: type,
    });
  }
}
