import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mirror-card',
  templateUrl: './mirror-card.component.html',
  styleUrls: ['./mirror-card.component.css']
})
export class MirrorCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
