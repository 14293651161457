import { AuthService } from './../../../services/auth.service';
import { DbService, leftJoinDocument } from './../../../services/db.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AlertDesignComponent } from '../../alert-design/alert-design.component';
import { ChatBoxComponent } from '../../template/chat-box/chat-box.component';
import { take, switchMap, map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-mypage-record-t-class',
  templateUrl: './mypage-record-t-class.component.html',
  styleUrls: ['./mypage-record-t-class.component.css'],
})
export class MypageRecordTClassComponent implements OnInit {
  exitId;
  classContent;
  exitInfo;
  exitFeed;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    public db: DbService,
    public auth: AuthService
  ) {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.exitId = params.exit;
    });

    this.db
      .doc$(`exitClass/${this.exitId}`)
      .pipe(take(1))
      .subscribe((data) => {
        if (data.classContent) {
          this.classContent = data.classContent;
        }
      });

    this.exitFeed = this.db
      .collection$(`exitClass`, (ref) => ref.where('exitId', '==', this.exitId))
      .pipe(
        switchMap((exitFeeds: any) => {
          if (exitFeeds.length > 0) {
            let reads$ = [];
            const feeds = exitFeeds[0].successFeed;

            feeds.forEach((feed) => {
              let tmp$ = this.db.doc$(`playUser/${feed}`);
              reads$.unshift(tmp$);
            });

            if (reads$.length > 0) {
              return combineLatest(reads$);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      )
      .pipe(
        map((cards: any) => {
          if (cards.length > 0) {
            cards.map((card) => {
              card.likeAll =
                (card.teacherlike ? card.teacherlike : 0) + card.like?.length;
              return card;
            });

            return cards.sort((a, b) => {
              return a.likeAll > b.likeAll ? -1 : a.likeAll < b.likeAll ? 1 : 0;
            });
          } else {
            return [];
          }
        })
      );
  }

  ngOnInit(): void {}

  // 댓글보기
  openComment(code): any {
    const dialogRef = this.dialog.open(ChatBoxComponent, {
      panelClass: 'chat_box',
      data: {
        feedCode: code,
      },
    });
  }

  // 목록으로
  openExit(): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: {
        type: 'leftOk',
        txt: '작성중이던 내용을 저장하지 않고 \n 목록으로 이동하시겠습니까?',
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data.data == 'ok') {
        this.router.navigate(['/mypage-t']);
      }
    });
  }

  // 저장하기
  openSave(): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: {
        txt: '작성 내용을 저장하시겠습니까?',
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data.data == 'ok') {
        this.router.navigate(['/mypage-t']);
        this.db.updateAt(`exitClass/${this.exitId}`, {
          classContent: this.classContent,
        });
      }
    });
  }
}
