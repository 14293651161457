<main id="content">
  <section class="left_box">
    <div *ngIf="exitFeed | async as feeds">
      <div class="card_wrap" *ngFor="let item of feeds">
        <div class="card_top">
          <div class="hastag">
            <span>#{{ item?.StepTwoCard?.title }}</span>
            <span>#{{ item?.StepThreeCard?.card }}</span>
          </div>
          <div class="info">
            <img src="assets/imgs/star_icon.png" alt="좋아요 순위 높음" />
            {{ (item?.studentId | doc: "student" | async)?.name }}
          </div>
        </div>

        <div class="txt_area">
          <p>
            {{ item?.successCard }}
          </p>
          <div class="btn_box">
            <button class="like">
              <img src="assets/imgs/heart_icon_on.png" alt="좋아요 활성화" />
              {{ item?.likeAll }}
            </button>
            <button class="comment" (click)="openComment(item.groupPlayId)">
              <img src="assets/imgs/comment_icon.png" alt="댓글보기" />
              {{ item?.reviewsId?.length }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="right_box">
    <div class="textarea_wrap">
      <textarea
        name=""
        id=""
        placeholder="*수업에 관련된 내용을 자유롭게 적어주시면 됩니다."
        maxlength="200"
        autofocus
        [(ngModel)]="classContent"
      ></textarea>
    </div>
    <div class="button_wrap">
      <button (click)="openExit()">목록으로</button>
      <button (click)="openSave()">저장하기</button>
    </div>
  </section>
</main>
