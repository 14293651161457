<div class="wrap_all">
  <loading-first *ngIf="loading"></loading-first>
  <div class="wrap">
    <div class="wrap_box">
      <div class="con">
        <div class="middle_box">
          <h1>OPEN THE AI WORLD</h1>

          <div class="form_box">
            <form [formGroup]="form">
              <fieldset>
                <legend>로그인</legend>
                <label for="uid">아이디</label>
                <input
                  type="text"
                  minlength="5"
                  maxlength="10"
                  [(ngModel)]="loginInfo.id"
                  formControlName="id"
                  id="uid"
                  name="uid"
                  class="uid"
                  placeholder="아이디를 입력하세요."
                />
                <label for="pwd">비밀번호</label>
                <input
                  type="password"
                  minlength="6"
                  maxlength="15"
                  [(ngModel)]="loginInfo.password"
                  formControlName="password"
                  id="pwd"
                  name="pwd"
                  class="pwd"
                  placeholder="비밀번호를 입력하세요."
                />

                <p class="vdt" *ngIf="!loginErr">
                  아이디 혹은 비밀번호가 잘못되었습니다. 다시 확인해주세요.
                </p>
                <p class="vdt" *ngIf="!exitUser">탈퇴한 회원입니다.</p>
              </fieldset>
            </form>

            <div class="btn_box">
              <button class="login_btn" (click)="loginCheck()">로그인</button>
              <button hidden>회원가입</button>
            </div>
          </div>
        </div>
      </div>
      <p class="notice">
        <b>이 사이트는 구글 크롬 브라우저에 최적화되어있습니다.</b>
      </p>
    </div>
    <footer-share></footer-share>
  </div>
</div>
