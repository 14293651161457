import { InputNumberService } from './../../services/input-number.service';
import { DbService } from './../../services/db.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alert-design-just-word',
  templateUrl: './alert-design-just-word.component.html',
  styleUrls: ['./alert-design-just-word.component.css'],
})
export class AlertDesignJustWordComponent implements OnInit, OnDestroy {
  type;
  message;
  alertData;
  teacherCode;
  playSwitch;
  openType;
  exitType;
  addExit;

  alertready: Observable<any>;

  subscription;

  constructor(
    public dialogRef: MatDialogRef<AlertDesignJustWordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public db: DbService,
    public inputNumber: InputNumberService
  ) {
    this.teacherCode = localStorage.getItem('teacherCode');
    this.alertData = data;
    this.type = data.type;
    this.message = data.txt;
    this.openType = data.openType;
    this.exitType = data.exitType;
    this.addExit = data.addExit;
  }

  async ngOnInit() {
    this.teacherCode = localStorage.getItem('teacherCode');
    if (this.openType) {
      this.alertready = this.db.collection$('teacher', (ref) =>
        ref.where('teacherCode', '==', this.teacherCode)
      );
      this.subscription = this.alertready.subscribe((data) => {
        this.playSwitch =
          data && data[0] && data[0].playSwitch ? data[0].playSwitch : false;

        // 현재 게임 진행중이라면
        if (this.playSwitch) {
          if (this.inputNumber.timerSwitch) {
            this.inputNumber.timerSwitch = false;
          }

          this.dialogRef.close({
            playType: this.playSwitch,
            onlyOne: 1,
          });
        }
      });
    } else if (this.exitType) {
      await this.db
        .collection$('teacher', (ref) =>
          ref.where('teacherCode', '==', this.teacherCode)
        )
        .subscribe((data) => {
          // 타이머 추가했으면
          if (data[0].addTimer) {
            this.dialogRef.close({ teacherId: data[0].teacherId });

            this.inputNumber.addTimer(data[0].addTimer);
          } else {
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.playSwitch) {
      this.subscription.unsubscribe();
    }
  }
}
