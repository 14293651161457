import { AuthService } from './../../services/auth.service';
import { DbService } from './../../services/db.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.css'],
})
export class MypageComponent implements OnInit {
  myTab = 'faq';
  p1: number = 1;
  p2: number = 1;
  searchKeyword: string = '';

  pageSize;
  p: number = 1;
  list: Array<any> = [];

  allFaqs$;
  faqs$;
  notices$;

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    // get FAQ
    this.faqs$ = this.db.collection$(`faq`, (ref) =>
      ref.orderBy('dateCreated', 'desc')
    );

    // get 공지사항
    this.notices$ = this.db.collection$(`notice`, (ref) =>
      ref.orderBy('dateCreated', 'desc')
    );
  }

  // FAQ 키워드로 검색
  searchFaq() {
    this.faqs$ = this.allFaqs$.pipe(
      map((faqs: any) => {
        return faqs.filter((ele) => ele.title.indexOf(this.searchKeyword) > -1);
      })
    );
  }

  // 마지막 페이지로 이동
  lastPage() {
    this.p1 = Math.ceil(this.list.length / 4);
  }

  // 첫번째 페이지로 이동
  firstPage() {
    this.p1 = 1;
  }
}
