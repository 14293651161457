import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-play-start',
  templateUrl: './play-start.component.html',
  styleUrls: ['./play-start.component.css'],
})
export class PlayStartComponent implements OnInit {
  code: string = '';
  name: string = '';
  gameType: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db: DbService,
    private common: CommonService
  ) {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.name = params.name; // 이름
      this.gameType = params.gameType; // 게임타입

      // 그룹 플레이일경우 선생님 코드도 전달받음
      if (this.gameType == 'group') {
        this.code = params.code;
      }
    });
  }

  ngOnInit(): void {}

  // 'START' 버튼 클릭 시
  async gameStart(): Promise<void | boolean> {
    const startGameCheck = await this.startGameCheck();
    const teacherReadyGameCheck = await this.teacherReadyGameCheck();
    if (!startGameCheck) {
      alert('현재 게임이 진행중입니다.');
      return false;
    }

    if (this.gameType == 'group' && !teacherReadyGameCheck) {
      alert('선생님 입장 전입니다.');
      return false;
    }

    const userId = this.common.generateFilename();
    localStorage.setItem('userId', userId);
    localStorage.setItem('userType', 'student');
    localStorage.setItem('teacherCode', this.code);
    const gameType = this.gameType == 'group' ? 'groupPlay' : 'personalPlay';

    await this.db.updateAt(`student/${userId}`, {
      id: userId,
      name: this.name,
      teacherCode: this.code,
      allPlayFeed: [],
      currentPlay: '',
      playGame: [],
      type: 'student',
      studendId: userId,
      gameType,
    });

    if (this.gameType == 'group') {
      this.groupPlaySetting();
    }

    this.router.navigate(['/play-personal'], {
      queryParams: {
        code: this.code,
        name: this.name,
        gameType: this.gameType,
      },
    });
  }

  // 선생님이 현재 게임 진행중인지 체크
  async startGameCheck(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection$('teacher', (ref) =>
          ref.where('teacherCode', '==', this.code)
        )
        .pipe(take(1))
        .subscribe((teachers) => {
          if (teachers) {
            const teacher = teachers[0];
            // 선생님이 이미 게임 진행중
            if (teacher && teacher.playSwitch) {
              resolve(false);
            } else {
              // 게임 진행하고 있지 않음
              resolve(true);
            }
          } else {
            resolve(false);
          }
        });
    });
  }

  // 선생님이 로그인 하여 '플레이' 탭에 왔는지 체크
  async teacherReadyGameCheck(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection$('teacher', (ref) =>
          ref.where('teacherCode', '==', this.code)
        )
        .pipe(take(1))
        .subscribe((teachers) => {
          if (teachers) {
            const teacher = teachers[0];
            // 선생님이 플레이 탭에 접속
            if (teacher && teacher.readySwitch) {
              resolve(true);
            } else {
              // 탭에 접속 X
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
    });
  }

  // 나의 userId를 선생님의 groupStudent에 추가
  async groupPlaySetting(): Promise<void> {
    this.db
      .collection$('teacher', (ref) =>
        ref.where('teacherCode', '==', this.code)
      )
      .pipe(take(1))
      .subscribe((test) => {
        firebase.default.firestore().runTransaction((transaction) => {
          return transaction
            .get(
              firebase.default
                .firestore()
                .collection('teacher')
                .doc(test[0].teacherId)
            )
            .then(
              (eventDoc) => {
                return transaction.update(
                  firebase.default
                    .firestore()
                    .collection('teacher')
                    .doc(test[0].teacherId),
                  {
                    groupStudent:
                      firebase.default.firestore.FieldValue.arrayUnion(
                        localStorage.getItem('userId')
                      ),
                  }
                );
              },
              (err) => {}
            );
        });
      });
  }
}
