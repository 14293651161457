/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DbService } from '../services/db.service';

@Pipe({
  name: 'doc',
})
export class DocPipe implements PipeTransform {
  constructor(public db: DbService) {}

  // db (doc) 연결 pipe
  transform(value: any, title): Observable<any> {
    return this.db.doc2$(`${title}/${value}`);
  }
}
