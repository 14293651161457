<div class="wrap">
    <h2>
        OPEN THE AI WORLD
    </h2>

    <div class="service_wrap">
        <div class="btn_wrap">
        <button class="service" (click)="openPersonal('serviceTerm')">
            이용약관
        </button>
        <button (click)="openPersonal('privacy')">
            개인정보처리방침
        </button>
    </div>

    <span class="copy_txt">&copy; 2020 by UMAKERS</span>
    </div>
</div>