import { AuthService } from './../../../services/auth.service';
import { DbService, leftJoinQuery } from 'src/app/services/db.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AlertDesignOnebuttonComponent } from '../../alert-design-onebutton/alert-design-onebutton.component';
import { AlertDesignComponent } from '../../alert-design/alert-design.component';
import { ActivatedRoute, Router } from '@angular/router';
import { take, switchMap } from 'rxjs/operators';
import { param } from 'jquery';
import * as firebase from 'firebase/app';
import { of, combineLatest } from 'rxjs';

@Component({
  selector: 'chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css'],
})
export class ChatBoxComponent implements OnInit {
  userId;
  feedcode;
  update = false;

  feedSelect$;
  review$;

  review = {
    userId: '',
    reviewsId: '',
    dateCreated: new Date().toISOString(),
    content: '',
  };
  report = {
    reportId: '',
    dateCreated: '',
    userId: '',
    type: '',
    reviewsId: '',
    cardId: '',
  };

  updateContent;
  swearword;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChatBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    public db: DbService,
    public auth: AuthService
  ) {
    this.feedcode = data.feedCode;
    this.userId = localStorage.getItem('userId');

    this.feedSelect$ = this.db.doc$(`playUser/${this.feedcode}`);

    this.review$ = this.feedSelect$.pipe(
      switchMap((review: any) => {
        let reviews = review.reviewsId ? review.reviewsId : [];
        let read$ = [];

        // 리뷰 id들을 가져와서 상세 데이터들을 가져옴
        for (const content of reviews) {
          if (content) {
            let tmp = this.db.doc$(`reviews/${content}`);
            read$.unshift(tmp);
          } else {
            read$.unshift(of(null));
          }
        }

        if (read$.length > 0) {
          return combineLatest(read$);
        } else {
          return of([]);
        }
      })
    );
  }

  // 욕설 데이터 정보 가져오기
  ngOnInit(): void {
    this.swearword = this.db.doc$('swearword/eaOri6Bzsqwj9BAG6DAR');
  }

  // 욕설 댓글 등록 X
  openWarning(): any {
    const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
      data: { txt: '욕설을 입력할 수 없습니다.' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.review.content = '';
      this.updateContent = '';
    });
  }

  //댓글 신고
  openReport(id, type): any {
    this.report.reportId = this.db.createFsId();
    this.report.reviewsId = id;
    this.report.userId = this.userId;
    this.report.dateCreated = new Date().toISOString();
    this.report.type = type;
    this.report.cardId = this.feedcode;

    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '댓글을 신고하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data.data == 'ok') {
        this.openAfterReport();
        this.db.updateAt(`report/${this.report.reportId}`, {
          ...this.report,
        });
      }
    });
  }

  //신고 이후
  openAfterReport(): any {
    const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
      data: { txt: '해당 댓글이 신고되었습니다.' },
    });
  }

  //댓글 삭제
  openRemove(): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '댓글을 삭제하시겠습니까?' },
    });
  }

  close() {
    this.dialogRef.close();
  }

  // 제출 함수
  submit() {
    this.swearword.subscribe((data) => {
      const warn = data.words.filter(
        (ele) => this.review.content.indexOf(ele) > -1
      );

      // warn.length > 0 ==> 욕설이 들어가있음
      if (warn.length == 0) {
        this.review.userId = this.userId;
        this.review.reviewsId = this.db.createFsId();
        this.db
          .updateAt(`reviews/${this.review.reviewsId}`, {
            ...this.review,
          })
          .then(() => {
            this.review.content = '';
          });
        this.db.updateAt(`playUser/${this.feedcode}`, {
          reviewsId: firebase.default.firestore.FieldValue.arrayUnion(
            this.review.reviewsId
          ),
        });
      } else {
        this.openWarning();
      }
    });
  }

  textUpdate(con) {
    con.update = true;
  }

  // 댓글 삭제 함수
  reviewDelete(con) {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '댓글을 삭제하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.data == 'ok') {
        this.db.updateAt(`playUser/${this.feedcode}`, {
          reviewsId: firebase.default.firestore.FieldValue.arrayRemove(
            con.reviewsId
          ),
        });

        this.db.delete(`reviews/${con.reviewsId}`);
      }
    });
  }

  // 댓글 등록 함수
  reviewRegister(con) {
    this.swearword.subscribe((data) => {
      const warn = data.words.filter(
        (ele) => this.updateContent.indexOf(ele) > -1
      );
      if (warn.length == 0) {
        con.update = false;
        this.db
          .updateAt(`reviews/${con.reviewsId}`, {
            content: this.updateContent,
          })
          .then(() => {
            this.updateContent = '';
          });
      } else {
        this.openWarning();
      }
    });
  }
}
