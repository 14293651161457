import { DbService } from './../../../services/db.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import * as $ from 'jquery';
import { switchMap } from 'rxjs/operators';
import { combineLatest, of, Observable } from 'rxjs';
@Component({
  selector: 'future-card',
  templateUrl: './future-card.component.html',
  styleUrls: ['./future-card.component.css'],
})
export class FutureCardComponent implements OnInit {
  public userId;
  public playCards;

  public selectedStepTitles: any = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FutureCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dbService: DbService
  ) {
    this.userId = localStorage.getItem('userId');

    this.playCards = this.dbService
      .collection$(`student`, (ref) =>
        ref.where('studendId', '==', this.userId)
      )
      .pipe(
        switchMap((playCard: any) => {
          if (playCard.length > 0) {
            let cards$ = [];
            const cards = playCard[0].playGame;

            cards.forEach((cardInfo) => {
              let tmp$ = this.dbService.doc$(`playUser/${cardInfo}`);
              cards$.unshift(tmp$);
            });

            if (cards$.length > 0) {
              return combineLatest(cards$);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.playCards.subscribe((Data) => {
      if (Data && Data.length > 0) {
        Data.forEach((element) => {
          if (element.StepTwoCard) {
            this.selectedStepTitles.push(element.StepTwoCard.title);
          }
        });
      }
    });
  }

  disabledChk(value) {
    const val = value.title;
    if (this.selectedStepTitles.indexOf(val) > -1) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    let dataI = this.data.length;

    $(function () {
      for (let i = 1; i <= dataI; i++) {
        if (i == 1) {
          $('.card_word:first-child').css({ left: '0%', top: 0, opacity: 1 });
        } else if (i > 1 && i <= 6) {
          //옆으로 똑같이
          $('.card_word:nth-child(' + i + ')').animate(
            {
              left: (i - 1) * 17 + '%',
              opacity: 1,
              top: '0%',
            },
            130 * i,
            'swing'
          );
        } else if (i >= 7 && i <= 12) {
          //밑으로 한번 옆으로 똑같이
          $('.card_word:nth-child(' + i + ')')
            .css({ top: '14.63rem', opacity: 1 })
            .animate(
              {
                left: (i - 7) * 17 + '%',
              },
              130 * (i - 6),
              'swing'
            );
        } else {
          $('.card_word:nth-child(' + i + ')')
            .css({ top: '30.26rem', opacity: 1 })
            .animate(
              {
                left: (i - 13) * 17 + '%',
              },
              130 * (i - 12),
              'swing'
            );
        }
      }
    });
  }

  openBigItemCard(item, allitem): any {
    this.dialogRef.close({
      data: item,
      alldata: allitem,
      type: 'fCard',
    });
  }

  justClose(): any {
    this.dialogRef.close();
  }
}
