import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CustomReuseStrategy } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoadingFirstComponent } from './pages/loading-first/loading-first.component';
import { LoginComponent } from './pages/account/login/login.component';
import { FooterShareComponent } from './pages/share/footer-share/footer-share.component';
import { HeaderShareComponent } from './pages/share/header-share/header-share.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertDesignComponent } from './pages/alert-design/alert-design.component';
import { TermsComponent } from './pages/terms/terms/terms.component';
import { MypageComponent } from './pages/mypage/mypage.component';
import { PlayLoadingComponent } from './pages/play-loading/play-loading.component';
import { PlaySelectComponent } from './pages/play-select/play-select.component';
import { PlayPersonalComponent } from './pages/play-personal/play-personal.component';
import { FutureCardComponent } from './pages/template/future-card/future-card.component';
import { UtilizationCardComponent } from './pages/template/utilization-card/utilization-card.component';
import { SpeakerCardComponent } from './pages/template/item-card/speaker-card/speaker-card.component';
import { BagCardComponent } from './pages/template/item-card/bag-card/bag-card.component';
import { BicycleCardComponent } from './pages/template/item-card/bicycle-card/bicycle-card.component';
import { GlassesCardComponent } from './pages/template/item-card/glasses-card/glasses-card.component';
import { BlackboardCardComponent } from './pages/template/item-card/blackboard-card/blackboard-card.component';
import { MirrorCardComponent } from './pages/template/item-card/mirror-card/mirror-card.component';
import { ItemCardBigComponent } from './pages/template/item-card-big/item-card-big.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PlayPersonalWriteComponent } from './pages/play-personal-write/play-personal-write.component';
import { CardBoardComponent } from './pages/template/item-card/card-board/card-board.component';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { TipComComponent } from './pages/template/tip-com/tip-com.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertDesignJustWordComponent } from './pages/alert-design-just-word/alert-design-just-word.component';
import { PlayGroupAllComponent } from './pages/play-group-all/play-group-all.component';
import { SidebarShareComponent } from './pages/share/sidebar-share/sidebar-share.component';
import { ChatBoxComponent } from './pages/template/chat-box/chat-box.component';
import { BoastingboardComponent } from './pages/boastingboard/boastingboard.component';
import { AlertDesignOnebuttonComponent } from './pages/alert-design-onebutton/alert-design-onebutton.component';
import { PlayComponent } from './pages/teacher/play/play.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { MypageRegisterTComponent } from './pages/teacher/mypage-register-t/mypage-register-t.component';
import { MypageRegisterTClassComponent } from './pages/teacher/mypage-register-t-class/mypage-register-t-class.component';
import { MypageRecordTClassComponent } from './pages/teacher/mypage-record-t-class/mypage-record-t-class.component';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import firebase from 'firebase';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { InputCodeComponent } from './pages/input-code/input-code.component';
import { InputNameComponent } from './pages/input-name/input-name.component';
import { PlayStartComponent } from './pages/play-start/play-start.component';
import { PlayPersonalDownloadComponent } from './pages/play-personal-download/play-personal-download.component';

firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoadingFirstComponent,
    LoginComponent,
    FooterShareComponent,
    HeaderShareComponent,
    AlertDesignComponent,
    TermsComponent,
    MypageComponent,
    PlayLoadingComponent,
    PlaySelectComponent,
    PlayPersonalComponent,
    FutureCardComponent,
    UtilizationCardComponent,
    SpeakerCardComponent,
    BagCardComponent,
    BicycleCardComponent,
    GlassesCardComponent,
    BlackboardCardComponent,
    MirrorCardComponent,
    ItemCardBigComponent,
    PlayPersonalWriteComponent,
    CardBoardComponent,
    TipComComponent,
    AlertDesignJustWordComponent,
    PlayGroupAllComponent,
    SidebarShareComponent,
    ChatBoxComponent,
    BoastingboardComponent,
    AlertDesignOnebuttonComponent,
    PlayComponent,
    MypageRegisterTComponent,
    MypageRegisterTClassComponent,
    MypageRecordTClassComponent,
    InputCodeComponent,
    InputNameComponent,
    PlayStartComponent,
    PlayPersonalDownloadComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    MatExpansionModule,
    PipeModule,
  ],
  entryComponents: [
    CardBoardComponent,
    FutureCardComponent,
    UtilizationCardComponent,
    ItemCardBigComponent,
    TipComComponent,
    AlertDesignComponent,
    AlertDesignJustWordComponent,
    AlertDesignOnebuttonComponent,
    ChatBoxComponent,
    MypageRegisterTClassComponent,
    PlayPersonalDownloadComponent,
  ],
  providers: [
    FormBuilder,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
