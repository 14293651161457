<div class="wrap_all">
  <header-share></header-share>
  <main id="content">
    <section class="top_box">
      <h2>
        인공지능과 함께하는 미래 세상을 열어라 !
        <b>Open the AI World</b>
      </h2>

      <p>
        인공지능 시대에 변화될 새로운 세상은 어떤 모습일까요?<br />
        인공지능 기술이 우리 일상을 만난 모습을 마음껏 상상해보세요!
      </p>
    </section>

    <section class="home_video">
      <h2 class="hidden">튜토리얼 비디오</h2>
      <div class="video_wrap">
        <video id="videoPlayer" style="width: 100%" controls="true">
          <source src="assets/videos/OpentheAIWorld.mp4" />
          이 브라우저는 video를 지원하지 않습니다.
        </video>
      </div>
    </section>
  </main>
  <footer-share></footer-share>
</div>
