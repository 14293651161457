import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-play-loading',
  templateUrl: './play-loading.component.html',
  styleUrls: ['./play-loading.component.css'],
})
export class PlayLoadingComponent implements OnInit {
  name: string = '';
  code: string = '';
  gameType: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.name = params.name;
      this.code = params.code;
      this.gameType = params.gameType;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      // when play-loading page, move page
      if (this.router.url.indexOf('play-loading') == -1) return false;
      this.goGame();
    }, 4500);
  }

  // 게임 start
  goGame(): void {
    this.router.navigate(['/play-start'], {
      queryParams: {
        code: this.code,
        name: this.name,
        gameType: this.gameType,
      },
    });
  }
}
