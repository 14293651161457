import { AuthService } from './../../../services/auth.service';
import { DbService } from './../../../services/db.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MypageRegisterTClassComponent } from '../mypage-register-t-class/mypage-register-t-class.component';

@Component({
  selector: 'app-mypage-register-t',
  templateUrl: './mypage-register-t.component.html',
  styleUrls: ['./mypage-register-t.component.css'],
})
export class MypageRegisterTComponent implements OnInit {
  myTab = 'register';

  p1: number = 1;
  p2: number = 1;

  finalClass$;
  keyWord;

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    if (localStorage.getItem('tab')) {
      this.myTab = localStorage.getItem('tab');
    }

    this.finalClass$ = this.db.collection$(`exitClass`, (ref) =>
      ref
        .where('teacherId', '==', localStorage.getItem('userId'))
        .orderBy('dateCreated', 'desc')
    );
  }

  openRegister(exitId): any {
    const dialogRef = this.dialog.open(MypageRegisterTClassComponent, {
      panelClass: 'register-pop',
      data: {
        exit: exitId,
      },
    });
  }

  // 페이지 이동 함수
  openRecord(exitId): any {
    this.router.navigateByUrl(
      this.router.createUrlTree(['/mypage-record'], {
        queryParams: {
          exit: exitId,
        },
      })
    );
  }

  // 시간 계산 함수
  getMinutes(start, end) {
    return new Date(end).getMinutes() - new Date(start).getMinutes();
  }

  ngOnInit(): void {}

  tabValue() {
    localStorage.setItem('tab', this.myTab);
  }
}
