<div class="chat_wrap">
  <div class="top_area" *ngIf="feedSelect$ | async as feedselect">
    <h2>
      {{ (feedselect?.studentId | doc: "student" | async)?.name }}&nbsp;님
    </h2>

    <button (click)="close()">
      <img src="assets/imgs/close_b.png" alt="닫기 버튼" />
    </button>
  </div>

  <div class="chat_area">
    <h3 class="hidden">댓글란</h3>
    <ul *ngIf="review$ | async as reviewer">
      <li *ngFor="let content of reviewer">
        <div class="chat_line" *ngIf="!content?.update">
          <span class="uname">{{
            (content.userId | doc: "student" | async)?.name
              ? (content.userId | doc: "student" | async)?.name
              : (content.userId | doc: "teacher" | async)?.name
          }}</span>
          <span class="chat">
            {{ content.content }}
          </span>
          <div class="time_area">
            <span class="date">{{
              content.dateCreated | date: "yy.MM.dd"
            }}</span>
            <button
              class="report"
              *ngIf="content.userId != userId"
              (click)="openReport(content.reviewsId, 'group')"
            >
              신고
            </button>
            <!--내 글일때-->
            <button
              class="edit"
              (click)="textUpdate(content)"
              *ngIf="content.userId == userId"
            >
              수정
            </button>
          </div>
        </div>

        <!--내글일때 수정버튼 누르면 input 등장-->
        <div class="chat_line my_chat" *ngIf="content.update">
          <span class="uname">{{
            (content.userId | doc: "student" | async)?.name
              ? (content.userId | doc: "student" | async)?.name
              : (content.userId | doc: "teacher" | async)?.name
          }}</span>
          <div class="edit_chat">
            <input
              type="text"
              [(ngModel)]="updateContent"
              [placeholder]="content.content"
              name=""
              id=""
              maxlength="20"
            />
            <div class="time_area">
              <button class="remove" (click)="reviewDelete(content)">
                삭제
              </button>
              <button class="edit" (click)="reviewRegister(content)">
                등록
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="input_area">
    <input
      type="text"
      name=""
      id=""
      maxlength="20"
      [(ngModel)]="review.content"
      placeholder="댓글을 입력해주세요. (20자 이내)"
    />
    <button [disabled]="review.content.length == 0" (click)="submit()">등록</button>
  </div>
</div>
