import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  generateFilename() {
    var length = 28;
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * 이름 변환 함수 정재은 => 정OO, 고현 => 고O, 강다니엘 => 강OOO
   * @param name 변환할 풀네임
   * @param name 이름을 나타낼 문자
   *
   */
  getSecretName(name, text) {
    let tmp_name = name.substring(0, 1);
    if (tmp_name.length > 2) {
      for (var i = 1; i < name.length; i++) {
        tmp_name += text;
      }
    } else {
      for (var i = 1; i < 3; i++) {
        tmp_name += text;
      }
    }
    return tmp_name;
  }

  /**
   * object array 중복 제거할 함수
   * @param originalArray 중복제거할 array
   * @param prop 제거 object key 값
   *
   */
  removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  /**
   * 오름차순 정렬 함수
   * @param property 정렬 기준 컬럼
   *
   */
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  /**
   * 위도, 경도로 거리를 구하는 함수
   * @param coords1 현재위치 (위도 경도) {latitude: Number, longitude: Number}
   * @param coords2 목적지위치 (위도 경도) {latitude: Number, longitude: Number}
   * @param unit 타입 ('K'일 경우 km, 'N'일 경우 m)
   *
   */
  getDistance(facCoords, myCoords, unit) {
    let radlat1 = (Math.PI * facCoords.latitude) / 180;
    let radlat2 = (Math.PI * myCoords.latitude) / 180;
    let theta = facCoords.longitude - myCoords.longitude;
    let radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }

  /**
   * 생년으로 올해 나이를 구하는 함수
   * @param birthYear 생년 (number)
   *
   */
  calcAge(birthYear) {
    let nowYear = new Date().getFullYear();
    let age = nowYear - birthYear + 1;
    return age;
  }

  /**
   * date를 포맷하는 함수 (new Date()) => 2020-07-20
   *
   * @param date 날짜
   *
   */
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  /**
   * var list = {"you": 100, "me": 75, "foo": 116, "bar": 15};
   * [{key:"bar", value:15}, {key:"me", value:75}, {key:"you", value:100}, {key:"foo", value:116}]
   * object 정렬 함수
   *
   * @param obj 정렬할 obj
   *
   */
  sortObject(obj) {
    var arr = [];
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        arr.push({
          key: prop,
          value: obj[prop],
        });
      }
    }
    arr.sort(function (a, b) {
      return b.value - a.value;
    });
    return arr; // returns array
  }

  /**
   * 배열 중 제일 큰 값의 index를 리턴하는 함수
   * @param arr 정렬할 배열
   *
   */
  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }
    return maxIndex;
  }
}
