<div class="floating_wrap">
    <div class="floating_con">
        <h1>OPEN THE AI WORLD</h1>

        <div class="con_txt">
            <h2>AI월드에 오신 여러분 환영합니다.</h2>

            <p>Open the AI World는 우리 일상 속 물건과 인공지능 기술을 더해<br>나만의 스마트한 물건으로 새로운 세상을 만들어 보는 보드게임입니다.</p>
        </div>
    </div>


</div>