<main class="play_wrap">
  <section class="top_box">
    <h1>OPEN THE AI WORLD</h1>
    <p>카드를 조합하여 <span>나만의 인공지능 아이템</span>을 만드세요!</p>
  </section>

  <section class="tutorial_box">
    <h2 class="hidden">OPEN THE AI WORLD는?</h2>
    <article class="key_box1">
      <p class="keyword_box">
        미래기술 <br />
        카드
      </p>
      <p>
        인공지능<br />
        기술과 관련된 <br />미래기술
      </p>
    </article>
    <span class="plus_icon appear1">+</span>
    <article class="key_box2">
      <p class="keyword_box">
        관심분야 <br />
        카드
      </p>
      <p>
        생활을 더욱<br />
        스마트 하게<br />
        만들 물건
      </p>
    </article>
    <span class="plus_icon appear2">+</span>
    <article class="key_box3">
      <p class="keyword_box">
        활용분야 <br />
        카드
      </p>
      <p>
        인공지능<br />
        기술이 활용된<br />
        분야
      </p>
    </article>
  </section>
</main>
