<!--모든 카드는 버튼에 disabled 걸면 선택됐을때 안됐을때 상황에 따라 보여집니다.-->

<main class="play_wrap">
  <!--사이드 메뉴-->
  <sidebar-share [inputNumber]="inputnumber.inputnumber"></sidebar-share>

  <!--메인화면-->
  <div class="play_board">
    <h2 class="hidden">게임 플레이 화면</h2>
    <div class="progress_wrap">
      <div
        class="pro_bar"
        [ngStyle]="{ width: gameType !== 'personal' ? '100%' : '89.552%' }"
      >
        <mat-progress-bar
          mode="determinate"
          value="{{ progressbar }}"
        ></mat-progress-bar>
        <div class="pro_txt">
          <span>STEP 1</span>
          <span>STEP 2</span>
          <span>STEP 3</span>
        </div>
      </div>
      <button *ngIf="gameType == 'personal'" (click)="openExitAlert()">
        나가기
        <img src="assets/imgs/exit_icon.png" alt="나가기 아이콘" />
      </button>
    </div>
    <!-- </div> -->

    <div class="item_card_area">
      <h2 class="hidden">물건 카드 선택</h2>
      <button
        class="item_card_box"
        [disabled]="disabled == 'mirror' || disabled == 'all'"
      >
        <mirror-card class="item_card" (click)="noClick ? '' : openItemBoard('mirror')"></mirror-card>
      </button>
      <button
        class="item_card_box"
        [disabled]="disabled == 'speaker' || disabled == 'all'"
      >
        <speaker-card class="item_card" (click)="noClick ? '' : openItemBoard('speaker')"></speaker-card>
      </button>
      <button
        class="item_card_box"
        [disabled]="disabled == 'bicycle' || disabled == 'all'"
      >
        <bicycle-card class="item_card" (click)="noClick ? '' : openItemBoard('bicycle')"></bicycle-card>
      </button>
      <button
        class="item_card_box"
        [disabled]="disabled == 'glasses' || disabled == 'all'"
      >
        <glasses-card class="item_card" (click)="noClick ? '' : openItemBoard('glasses')"></glasses-card>
      </button>
      <button
        class="item_card_box"
        [disabled]="disabled == 'blackboard' || disabled == 'all'"
      >
        <blackboard-card class="item_card" (click)="noClick ? '' : openItemBoard('blackboard')"></blackboard-card>
      </button>
      <button
        class="item_card_box last_box"
        [disabled]="disabled == 'bag' || disabled == 'all'"
      >
        <bag-card class="item_card" (click)="noClick ? '' : openItemBoard('bag')"></bag-card>
      </button>
    </div>

    <div class="middle_wrap">
      <h2 class="hidden">미래기술 카드 선택</h2>
      <div class="left_area" *ngIf="futureWord | async | keyvalue as future">
        <button
          class="future_card"
          *ngFor="let item of future"
          (click)="openfutureCard(future)"
          [disabled]="
            item.value.title == disabledfuture || disabledfuture == 'all'
          "
        >
          <p>
            <span>{{ item.value.title }}</span>
          </p>
        </button>
      </div>

      <div class="center_area">
        <img
          *ngIf="stepIcon == '1'"
          src="assets/imgs/step1_icon.png"
          alt="step1 선택 아이콘"
        />
        <img
          *ngIf="stepIcon == '2'"
          src="assets/imgs/step2_icon.png"
          alt="step2 선택 아이콘"
        />
        <img
          *ngIf="stepIcon == '3'"
          src="assets/imgs/step3_icon.png"
          alt="step3 선택 아이콘"
        />
      </div>

      <h2 class="hidden">활용분야 카드 선택</h2>
      <div
        class="right_area"
        *ngIf="utilizationWord | async | keyvalue as utilization"
      >
        <button
          class="utiluzation_card"
          [ngStyle]="{ background: item.value.backcolor }"
          *ngFor="let item of utilization"
          (click)="openUtilizationCard(utilization)"
          [disabled]="
            item.value.card == disabledutilization ||
            disabledutilization2 == 'all'
          "
        >
          <p>
            <span [ngStyle]="{ color: item.value.color }">{{
              item.value.card
            }}</span>
          </p>
        </button>
      </div>
    </div>

    <!--화면 하단-->
    <div class="bottom_area">
      <!--카드 선택시마다 하단 멘트 변경-->
      <div class="card_ver" *ngIf="stepIcon == '1'">
        <h2>
          관심물건 선택
          <span>관심영역의 물건 카드를 선택하세요.</span>
        </h2>
      </div>
      <div class="card_ver" *ngIf="stepIcon == '2'">
        <h2>
          미래기술 선택
          <span>스마트 아이템을 만들기 위해 필요한 미래기술을 선택하세요.</span>
        </h2>
      </div>
      <div class="card_ver" *ngIf="stepIcon == '3'">
        <h2>
          활용분야 선택
          <span>스마트 아이템을 만들기 위해 활용하고 싶은 분야를 선택하세요.</span>
        </h2>
      </div>

      <div class="cho_card_area">
        <h3 class="hidden">선택한 카드</h3>

        <!--선택전-->
        <div class="cho_card_box" *ngIf="!pickItemCard">
          <div class="item_cho">
            <p></p>
          </div>
          <span>관심물건</span>
        </div>

        <!--선택후-->
        <div class="cho_card_box" *ngIf="pickItemCard">
          <div
            class="item_cho pic_card1"
            [ngStyle]="{ background: pickItemCard.outBg }"
          >
            <p [ngStyle]="{ background: pickItemCard.innerBg }">
              {{ pickItemCard.onlyTxt }}
            </p>
          </div>
          <span [ngClass]="{ pic_card: true }">관심물건</span>
        </div>

        <!--선택전-->
        <div class="cho_card_box" *ngIf="!pickFutureCard">
          <div class="item_cho">
            <p></p>
          </div>
          <span>미래기술</span>
        </div>

        <!--선택후-->
        <div class="cho_card_box" *ngIf="pickFutureCard">
          <div class="item_cho pic_card2">
            <p>{{ pickFutureCard }}</p>
          </div>
          <span [ngClass]="{ pic_card: true }">미래기술</span>
        </div>

        <!--선택전-->
        <div class="cho_card_box" *ngIf="!pickUtilizationCard">
          <div class="item_cho">
            <p></p>
          </div>
          <span [ngClass]="{ pic_card: false }">활용분야</span>
        </div>

        <!--선택후-->
        <div class="cho_card_box last_box" *ngIf="pickUtilizationCard">
          <div
            class="item_cho pic_card2 pic_card3"
            [ngStyle]="{ background: pickUtilizationCard.backcolor }"
          >
            <p [ngStyle]="{ color: pickUtilizationCard.color }">
              {{ pickUtilizationCard.card }}
            </p>
          </div>
          <span [ngClass]="{ pic_card: true }">활용분야</span>
        </div>
      </div>

      <div class="bt_btn_area">
        <button class="prev_btn" *ngIf="stepIcon != '1'" (click)="goBack()">
          <img src="assets/imgs/prev_btn.png" alt="" />
          <span class="btn_txt">이전단계</span>
        </button>
        <button
          class="next_btn"
          *ngIf="itemCardType && pickFutureCard && pickUtilizationCard && !front"
          (click)="
            goNext(itemCardType, pickFutureCard, pickUtilizationCard.card)
          "
        >
          <img src="assets/imgs/next_btn.png" alt="" />
          <span class="btn_txt">문장 만들기</span>
        </button>
        <!-- *ngIf="itemCardType || pickFutureCard || pickUtilizationCard" -->
        <button
          class="next_btn"
          *ngIf="(itemCardType || pickFutureCard || pickUtilizationCard) && front"
          (click)="goItem()"
        >
          <img src="assets/imgs/next_btn.png" alt="" />
          <span class="btn_txt">다음단계</span>
        </button>
      </div>
    </div>
    <!--//화면 하단 끝-->
  </div>
  <!--//메인화면 끝-->
</main>
<!--//play_wrap 끝-->
