<div class="header_wrap">
  <h1><a href="#">OPEN THE AI WORLD</a></h1>
  <div class="menu_wrap" *ngIf="user$ | async as user">
    <!--학생 메인메뉴-->
    <nav *ngIf="user.type == 'student'">
      <h2 class="hidden" (click)="hamburgerMenu()">메인 메뉴</h2>
      <ul [ngClass]="{ menu_on: menuOn }">
        <li>
          <a
            [routerLink]="['/home']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >AI월드 소개</a
          >
        </li>

        <li>
          <a href="#" (click)="logout()">플레이</a>
        </li>
        <li>
          <a
            href="#"
            [routerLink]="['/boastingboard']"
            routerLinkActive="active"
            >명예의 전당</a
          >
        </li>

        <li>
          <a [routerLink]="['/mypage']" routerLinkActive="active">게시판</a>
        </li>
      </ul>
    </nav>

    <!--선생님 메인메뉴-->
    <nav *ngIf="user.type == 'teacher'">
      <h2 class="hidden" (click)="hamburgerMenu()">선생님 메인 메뉴</h2>
      <ul [ngClass]="{ menu_on: menuOn }">
        <li>
          <a
            [routerLink]="['/play-t']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >플레이</a
          >
        </li>
        <li>
          <a [routerLink]="['/mypage-t']" routerLinkActive="active"
            >마이페이지</a
          >
        </li>
      </ul>
    </nav>

    <div class="user_menu">
      <h2 class="hidden">사용자 메뉴</h2>
      <ul>
        <li class="name_area" *ngIf="user.type == 'teacher'">
          선생님 코드 : {{ user?.teacherCode }}
        </li>
        <li class="name_area">{{ user?.name }} 님</li>
        <li (click)="openLogout()">
          <a href="#" onclick="return false">로그아웃</a>
        </li>
      </ul>
    </div>
  </div>
</div>
