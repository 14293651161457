import { TimerService } from './../../../services/timer.service';
import { leftJoinQuery } from './../../../services/db.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { AlertDesignComponent } from '../../alert-design/alert-design.component';
import * as firebase from 'firebase';
import { PlayPersonalDownloadComponent } from '../../play-personal-download/play-personal-download.component';

@Component({
  selector: 'sidebar-share',
  templateUrl: './sidebar-share.component.html',
  styleUrls: ['./sidebar-share.component.css'],
})
export class SidebarShareComponent implements OnInit, OnDestroy {
  boardSwitch = false;
  pickText = false;

  playGameKey = [];
  playGameKey2 = [];

  userId;
  user$;
  userInfo;

  playCount = 10;
  progressbarValue;
  progressbarClass;
  curSec: number = 0;

  playType;

  url;

  alreadyFeed;
  selectFeed;
  currentUrl;

  defaultPlayTime = 20;
  middleTime;
  timerStr;

  test1;
  test2: Observable<any>;

  constructor(
    public dialog: MatDialog,
    private db: DbService,
    private auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public timer: TimerService
  ) {
    this.userId = localStorage.getItem('userId');
    this.userInfo = this.db.doc$(`student/${this.userId}`);
    this.user$ = this.db.afs
      .collection(`student`, (ref) => ref.where('studendId', '==', this.userId))
      .valueChanges()
      .pipe(leftJoinQuery(this.db.afs, 'teacherCode', 'teacher'));

    this.db.doc$(`student/${this.userId}`).subscribe((user) => {
      // 그룹플레이일 경우 (선생님이 있는 경우)
      // 그룹피드 가져오기
      if (user && user.teacherCode) {
        this.getAllFeedCards(user.teacherCode);
      }
      if (user && user.currentPlay) {
        this.db.doc$(`playRoom/${user.currentPlay}`).subscribe(async (game) => {
          await this.timer.stop();
          const now = new Date(game.dateCreated);

          let minutesume = 0;

          // middleTime 현재시간 + 기본시간
          this.middleTime = now.getTime() + this.defaultPlayTime * 60000;
          if (game.minutes.length > 0) {
            // 선생님이 시간을 추가 했을 경우
            game.minutes.forEach((minute) => {
              this.middleTime += minute.addtimer * 60000;
              minutesume += minute.addtimer * 60000;
            });
          }

          // 남은시간 = 종료시간 - 현재시간
          let calc = this.middleTime - new Date().getTime();

          this.timer
            .countdownSecond(Math.floor(calc / 1000))
            .subscribe((data) => {
              let minutes = data.minutes % 60;
              let seconds = data.seconds;

              let allsum = this.defaultPlayTime * 60000 + minutesume; // 전체 밀리세컨드 시간

              let mysum = this.middleTime - new Date().getTime();
              this.progressbarValue = (mysum / allsum) * 100;

              this.timerStr = `${('0' + minutes.toString()).slice(-2)}:${(
                '0' + seconds.toString()
              ).slice(-2)}`;

              if (this.timerStr <= '01:00') {
                this.progressbarClass = true;
              } else {
                this.progressbarClass = false;
              }

              if (!game.activeSwitch) {
                this.timer.stop();
              }
            });
        });
      }
    });
  }

  studentCards: any = [];

  ngOnInit(): void {
    this.selectFeed = localStorage.getItem('feedSelect');
    this.currentUrl = this.router.url;
  }

  // 사이드메뉴 클릭시
  boardOn(): any {
    if (this.boardSwitch == false) {
      this.boardSwitch = true;
    } else {
      this.boardSwitch = false;
    }
  }

  goPersonalFeed(playUserDataId: string): void {
    const dialogRef = this.dialog.open(PlayPersonalDownloadComponent, {
      data: {
        playUserDataId,
      },
    });
  }

  // span 태그 선택시
  txtPick(data, all, type): any {
    if (type == 'personalPlay') {
      // playUser>data 에서 DB 가져와야됨
      this.goPersonalFeed(data);
      return;
    } else {
      if (all.length <= 2) {
        this.openNotAllow();
      } else if (all.length >= 3 && this.playGameKey.length == 0) {
        // 제출
        this.openPickSentence(data);
        this.alreadyFeed = data;
        this.playGameKey = [data];
      } else if (all.length >= 3 && this.playGameKey.length == 1) {
        // 변경
        this.openChange(data);
        this.playGameKey = [data];
      }
    }
  }

  playKey(data) {
    const index = this.playGameKey.indexOf(data);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  // 문장 선택
  openPickSentence(feedKey): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: {
        txt: '선택한 문장을 보드에 제출하시겠습니까?',
        selectFeed: feedKey,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.data == 'ok') {
        this.playGameKey = [feedKey];
        localStorage.setItem('feedSelect', feedKey);
        this.user$.pipe(take(1)).subscribe(async (data) => {
          this.alreadyFeed = feedKey;
          this.selectFeed = localStorage.getItem('feedSelect');

          this.chkFeed().then((result) => {
            if (result.result) {
              const prevUid = result.feedId;
              this.db.updateAt(`groupFeed/${data[0].teacherCode}`, {
                studentCard:
                  firebase.default.firestore.FieldValue.arrayRemove(prevUid),
              });
            }

            this.db.updateAt(`groupFeed/${data[0].teacherCode}`, {
              studentCard:
                firebase.default.firestore.FieldValue.arrayUnion(feedKey),
            });
          });
        });
      }
    });
  }

  // 등록한 피드 카드 가져오기 함수
  allFeedCards$;
  allFeedCards: any = [];
  getAllFeedCards(teacherCode) {
    this.allFeedCards$ = this.db.doc$(`groupFeed/${teacherCode}`).pipe(
      switchMap((doc) => {
        let reads$ = [];

        doc.studentCard.forEach((cardId) => {
          let tmp$ = this.db.doc$(`playUser/${cardId}`);
          reads$.unshift(tmp$);
        });

        if (reads$.length > 0) {
          return combineLatest(reads$);
        } else {
          return of([]);
        }
      })
    );

    this.allFeedCards$.subscribe((datas) => {
      this.allFeedCards = [];
      this.allFeedCards = datas;
    });
  }

  // 피드 카드 중 업데이트 하는 함수
  getChkMyFeedUpload(feedId) {
    if (this.allFeedCards && this.allFeedCards.length > 0) {
      let find = this.allFeedCards.find((ele) => ele.id == feedId);
      if (find) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 피드를 이미 올린것인지 체크
  chkFeed(): any {
    return new Promise<any>((resolve, reject) => {
      const uid = localStorage.getItem('userId');
      this.allFeedCards$.pipe(take(1)).subscribe((datas: any) => {
        if (datas && datas.length > 0) {
          let find = datas.find((ele) => ele.studentId == uid);
          if (find) {
            resolve({
              result: true,
              feedId: find.id,
            });
          } else {
            resolve({
              result: false,
            });
          }
        } else {
          resolve({
            result: false,
          });
        }
      });
    });
  }

  // 문장 선택시 보드에 3장이 5개 이하일때
  openNotAllow(): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '최소 3번 플레이 진행 후 제출 가능합니다.' },
    });
  }

  // 선택 문장 변경
  openChange(feedKey): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: {
        txt: '제출 문장을 변경하시면 \n좋아요와 댓글이 모두 삭제됩니다. \n변경하시겠습니까?',
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.data == 'ok') {
        localStorage.setItem('feedSelect', feedKey);
        this.playGameKey = [feedKey];
        this.selectFeed = localStorage.getItem('feedSelect');
        this.user$.pipe(take(1)).subscribe((data) => {
          this.chkFeed().then((result) => {
            if (result.result) {
              const prevUid = result.feedId;

              this.db.updateAt(`groupFeed/${data[0].teacherCode}`, {
                studentCard:
                  firebase.default.firestore.FieldValue.arrayRemove(prevUid),
              });
            }

            this.db.updateAt(`groupFeed/${data[0].teacherCode}`, {
              studentCard:
                firebase.default.firestore.FieldValue.arrayUnion(feedKey),
            });
          });
        });
      }
    });
  }

  // 페이지 이동 함수
  goBoard(code) {
    if (this.currentUrl.includes('/play-group-all')) {
      this.router.navigate(['/play-personal'], {
        queryParams: {
          gameType: 'group',
          only: '1',
        },
      });
    } else if (this.currentUrl.includes('/play-personal')) {
      this.router.navigate(['/play-group-all'], {
        queryParams: {
          teacherCode: code,
        },
      });
    }
  }

  ngOnDestroy() {}
}
