import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DbService, leftJoinDocument } from 'src/app/services/db.service';
import { AlertDesignOnebuttonComponent } from '../alert-design-onebutton/alert-design-onebutton.component';
import { AlertDesignComponent } from '../alert-design/alert-design.component';
import * as firebase from 'firebase';

@Component({
  selector: 'app-boastingboard',
  templateUrl: './boastingboard.component.html',
  styleUrls: ['./boastingboard.component.css'],
})
export class BoastingboardComponent implements OnInit {
  fame$;

  userId;
  updateContent;

  reviews = {
    reviewsId: '',
    content: '',
    dateCreated: '',
    userId: '',
  };

  report = {
    reportId: '',
    dateCreated: '',
    userId: '',
    type: '',
    reviewsId: '',
    cardId: '',
  };

  swearword;

  constructor(public dialog: MatDialog, private db: DbService) {
    this.userId = localStorage.getItem('userId');
    this.getFame();
  }

  ngOnInit(): void {
    this.swearword = this.db.doc$('swearword/eaOri6Bzsqwj9BAG6DAR');
  }

  // 명성 데이터 정보 불러오는 함수
  getFame() {
    const uid = localStorage.getItem('userId');
    this.fame$ = this.db
      .collection$(`fame`)
      .pipe(leftJoinDocument(this.db.afs, 'cardId', 'playUser'))
      .pipe(
        switchMap((feeds: any) => {
          let reads$ = [];

          feeds.forEach((feed) => {
            if (feed.cardId && feed.cardId.studentId) {
              let tmp$ = this.db.doc$(`student/${feed.cardId.studentId}`).pipe(
                map((student) => {
                  return { ...feed, student };
                })
              );

              reads$.unshift(tmp$);
            }
          });

          if (reads$.length > 0) {
            return combineLatest(reads$);
          } else {
            return of([]);
          }
        })
      )
      .pipe(
        map((feeds: any) => {
          return feeds.map((feed) => {
            if (feed.likes.indexOf(uid) > -1) {
              feed.likeSwitch = true;
            } else {
              feed.likeSwitch = false;
            }
            return feed;
          });
        })
      );
  }

  // 명성 좋아요 표시 유/무 함수
  heartFame(fame) {
    const uid = localStorage.getItem('userId');
    if (fame.likeSwitch) {
      this.db
        .updateAt(`fame/${fame.id}`, {
          likes: firebase.default.firestore.FieldValue.arrayRemove(uid),
        })
        .then(() => {
          fame.likeSwitch = false;
        });
    } else {
      this.db
        .updateAt(`fame/${fame.id}`, {
          likes: firebase.default.firestore.FieldValue.arrayUnion(uid),
        })
        .then(() => {
          fame.likeSwitch = true;
        });
    }
  }

  detailReply(gallery) {
    gallery.replySwitch = !gallery.replySwitch;
  }

  famedetailReply(fame) {
    fame.replySwitch = !fame.replySwitch;
  }

  // 욕설 댓글 등록 X
  openWarning(): any {
    const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
      data: { txt: '욕설을 입력할 수 없습니다.' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.reviews.content = '';
      this.updateContent = '';
    });
  }

  // 댓글 등록 함수
  openReviewUpdate(id): any {
    this.swearword.subscribe((data) => {
      const warn = data.words.filter(
        (ele) => this.reviews.content.indexOf(ele) > -1
      );
      if (warn.length == 0) {
        this.reviews.reviewsId = this.db.createFsId();
        this.reviews.dateCreated = new Date().toISOString();
        this.reviews.userId = localStorage.getItem('userId');

        const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
          data: { txt: '댓글이 등록되었습니다.' },
        });

        this.db.updateAt(`reviews/${this.reviews.reviewsId}`, {
          ...this.reviews,
        });

        this.db.updateAt(`playUser/${id}`, {
          reviewsId: firebase.default.firestore.FieldValue.arrayUnion(
            this.reviews.reviewsId
          ),
        });

        dialogRef.afterClosed().subscribe((data) => {
          this.reviews.content = '';
        });
      } else {
        this.openWarning();
      }
    });
  }

  //댓글 신고
  openReport(id, type, Card): any {
    if (Card.personalPlayId) {
      this.report.cardId = Card.personalPlayId;
    } else if (Card.groupPlayId) {
      this.report.cardId = Card.groupPlayId;
    }

    this.report.reportId = this.db.createFsId();
    this.report.reviewsId = id;
    this.report.userId = this.userId;
    this.report.dateCreated = new Date().toISOString();
    this.report.type = type;

    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '댓글을 신고하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.data == 'ok') {
        this.openAfterReport();
        this.db.updateAt(`report/${this.report.reportId}`, {
          ...this.report,
        });
      }
    });
  }

  //신고 이후
  openAfterReport(): any {
    const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
      data: { txt: '해당 댓글이 신고되었습니다.' },
    });
  }

  //신고 이후
  openRegister(con): any {
    this.swearword.subscribe((data) => {
      const warn = data.words.filter(
        (ele) => this.updateContent.indexOf(ele) > -1
      );
      if (warn.length == 0) {
        const dialogRef = this.dialog.open(AlertDesignOnebuttonComponent, {
          data: { txt: '댓글이 수정되었습니다.' },
        });
        con.update = false;
        this.db.updateAt(`reviews/${con.reviewsId}`, {
          content: this.updateContent,
        });
      } else {
        this.openWarning();
      }
    });
  }

  textUpdate(con) {
    con.update = true;
  }

  //댓글 삭제
  openRemove(con, playId): any {
    const dialogRef = this.dialog.open(AlertDesignComponent, {
      data: { txt: '댓글을 삭제하시겠습니까?' },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.data == 'ok') {
        this.db.updateAt(`playUser/${playId}`, {
          reviewsId: firebase.default.firestore.FieldValue.arrayRemove(
            con.reviewsId
          ),
        });

        this.db.delete(`reviews/${con.reviewsId}`);
      }
    });
  }
}
