<div
  (click)="boardOn()"
  [ngClass]="{ aside_wrap: boardSwitch }"
  *ngIf="user$ | async as users"
>
  <!--개인 플레이일때-->
  <span
    class="border_txt"
    *ngIf="(userInfo | async)?.gameType == 'personalPlay'"
    ><span>*</span> 작성된 텍스트를 눌러 이미지로 저장해 보세요.
  </span>

  <!--그룹 플레이일때 -->
  <span class="border_txt" *ngIf="(userInfo | async)?.gameType == 'groupPlay'"
    ><span>*</span> 한 문장을 선택해 보드에 제출해주세요.</span
  >

  <aside [ngClass]="{ aside_actvie: boardSwitch }" *ngFor="let user of users">
    <div class="life_area">
      <div class="life_box">
        <h3 class="hidden">총 가능한 게임 횟수</h3>
        <img src="assets/imgs/heart_icon.png" alt="" />
        <span>{{ playCount - user?.playGame?.length }}</span>
      </div>
      <!--프로그레스바 반대방향으로 진행
        시간 짧아지면 class no_tiem 추가
      -->
      <mat-progress-bar
        *ngIf="user?.gameType == 'groupPlay'"
        mode="determinate"
        [value]="progressbarValue"
        title="시간제한 바"
        class="pro_bar2"
        dir="rtl"
        [ngClass]="{ no_tiem: progressbarClass }"
      ></mat-progress-bar>
    </div>

    <div class="count_box">
      <h3 class="hidden">작성한 카드</h3>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user?.playGame && user?.playGame[0] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[0]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[0]
          }"
        >
          1
        </button>
        <!--글카드 클릭 선택시 액티브-->
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[0]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[0]
          }"
          (click)="
            txtPick(user?.playGame[0], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[0] | doc: "playUser" | async)?.successCard }}
        </span>
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[1] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[1]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[1]
          }"
        >
          2
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[1]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[1]
          }"
          (click)="
            txtPick(user?.playGame[1], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[1] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[2] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[2]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[2]
          }"
        >
          3
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[2]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[2]
          }"
          (click)="
            txtPick(user?.playGame[2], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[2] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[3] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[3]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[3]
          }"
        >
          4
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[3]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[3]
          }"
          (click)="
            txtPick(user?.playGame[3], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[3] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[4] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[4]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[4]
          }"
        >
          5
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[4]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[4]
          }"
          (click)="
            txtPick(user?.playGame[4], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[4] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[5] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[5]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[5]
          }"
        >
          6
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[5]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[5]
          }"
          (click)="
            txtPick(user?.playGame[5], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[5] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[6] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[6]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[6]
          }"
        >
          7
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[6]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[6]
          }"
          (click)="
            txtPick(user?.playGame[6], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[6] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[7] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[7]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[7]
          }"
        >
          8
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[7]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[7]
          }"
          (click)="
            txtPick(user?.playGame[7], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[7] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[8] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[8]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[8]
          }"
        >
          9
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[8]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[8]
          }"
          (click)="
            txtPick(user?.playGame[8], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[8] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
      <p>
        <button
          [ngClass]="{
            active_count:
              user && user.playGame && user?.playGame[9] ? true : false,
            pick_btn_txt:
              getChkMyFeedUpload(user?.playGame[9]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[9]
          }"
        >
          10
        </button>
        <span
          [ngClass]="{
            pick_txt:
              boardSwitch &&
              getChkMyFeedUpload(user?.playGame[9]) &&
              user?.gameType == 'groupPlay' &&
              selectFeed == user?.playGame[9]
          }"
          (click)="
            txtPick(user?.playGame[9], user?.playGame, user?.gameType);
            $event.stopPropagation()
          "
        >
          {{ (user?.playGame[9] | doc: "playUser" | async)?.successCard }}</span
        >
      </p>
    </div>

    <button
      class="go_board"
      (click)="goBoard(user?.teacherCode)"
      *ngIf="user?.gameType == 'groupPlay'"
    >
      <span>GO TO </span> BOARD <span>!!</span>
    </button>
  </aside>
</div>
