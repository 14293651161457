<div class="wrap">
    <div class="con" *ngIf="alertData">
        <div class="cant">
        <div class="txt_area">
            <p>{{message}}</p>
        </div>
        <div class="btn_wrap">
            <button (click)="close('ok')">{{button? button:'확인'}}</button>
        </div>
        </div>
    </div>
</div>
