<div
  class="big_card_wrap"
  *ngIf="type == 'item'"
  [ngStyle]="{ background: itemCard.outBg }"
>
  <div class="big_card_con">
    <button class="close_btn" (click)="popClose()">
      <img src="assets/imgs/close_icon.png" alt="닫기" />
    </button>
    <div class="con_box">
      <img [src]="itemCard.img" alt="스피커 아이콘" />
      <div class="text_box" [ngStyle]="{ background: itemCard.innerBg }">
        <p>{{ itemCard.txt }}</p>
        <button
          [ngStyle]="{ background: itemCard.innerBg }"
          (click)="cardPick(itemCard, 'item')"
        >
          카드선택하기
        </button>
      </div>
    </div>
  </div>
</div>

<div class="big_card_f_wrap" *ngIf="type == 'fCard'">
  <div class="big_card_f_con">
    <button class="close_btn" (click)="popClose()">
      <img src="assets/imgs/close_b.png" alt="닫기" />
    </button>
    <div class="con_box">
      <div class="text_box">
        <div class="txt">
          <h2>{{ data.data.title }}</h2>
          <p>{{ data.data.desc }}</p>
        </div>
        <button (click)="cardPick(data.data.title, 'future')">카드선택하기</button>
      </div>
    </div>
  </div>
</div>

<div
  class="big_card_u_wrap"
  [ngStyle]="{ background: data.data.backcolor }"
  *ngIf="type == 'uCard'"
>
  <div class="big_card_u_con">
    <button class="close_btn" (click)="popClose()">
      <img src="assets/imgs/close_b.png" alt="닫기" />
    </button>
    <div class="con_box">
      <div class="text_box">
        <div class="txt">
          <h2>{{ data.data.topic }}</h2>
          <p>{{ data.data.card }}</p>
        </div>
        <button
          [ngStyle]="{ color: data.data.color }"
          (click)="cardPick(data.data, 'utilization')"
        >
          카드선택하기
        </button>
      </div>
    </div>
  </div>
</div>
