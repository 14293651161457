import { UserInfoService } from './../../../services/userInfo.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  type: string = '';
  term$;
  termRef: string = 'master/term';

  constructor(
    private route: ActivatedRoute,
    private db: DbService,
    public userinfo: UserInfoService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.term$ = this.db.doc$(this.termRef);
      this.type = params.type;
    });
  }

  ngOnInit(): void {
    this.userinfo.getUserInfo().subscribe((info) => {});
  }
}
