<main class="board_wrap">
  <section class="main_board" *ngIf="feedData != null">
    <div class="for_wrap">
      <div
        [id]="myFeedCardId"
        class="card_wrap"
        [ngStyle]="{ background: feedData?.StepOneCard?.con.outBg }"
      >
        <div class="top_info">
          <div class="hashtag_area">
            <span [ngStyle]="{ background: feedData?.StepOneCard?.con.innerBg }"
              >#{{ feedData?.StepTwoCard?.title }}</span
            >
            <span [ngStyle]="{ background: feedData?.StepThreeCard?.color }"
              >#{{ feedData?.StepThreeCard?.card }}</span
            >
          </div>
          <div class="info_area">
            <span><img src="assets/imgs/star_icon.png" alt="top3" /></span>
            {{ myNickname }}
          </div>
        </div>

        <div class="txt_area">
          <p>{{ feedData?.successCard }}</p>
          <div class="btn_wrap">
            <button style="height: 2.7rem" (click)="downloadImg()">
              <img
                src="assets/imgs/download-icon.png"
                alt="다운로드"
                style="width: 1.7rem"
              />
              download
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
