<div class="future_card_wrap" (click)="justClose()">
  <div
    class="card_word"
    *ngFor="let item of data"
    [ngStyle]="{ background: item.value.backcolor }"
    (click)="disabledChk(item.value) ? '' : openBigItemCard(item.value, data); $event.stopPropagation()"
    [ngClass]="disabledChk(item.value) ? 'dis_css' : ''"
  >
    <p [ngStyle]="{ color: item.value.color }">{{ item.value.card }}</p>
  </div>
</div>
