import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DbService } from 'src/app/services/db.service';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-play-personal-download',
  templateUrl: './play-personal-download.component.html',
  styleUrls: ['./play-personal-download.component.css'],
})
export class PlayPersonalDownloadComponent implements OnInit {
  myNickname: string = '';
  myUid: string = localStorage.getItem('userId');

  playUserDataId: string = '';
  feedData: any = null;

  myFeedCardId: string = 'myFeedCard';

  constructor(
    private db: DbService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getMyNickname();
  }

  ngOnInit(): void {
    this.playUserDataId = this.data.playUserDataId;
    this.getFeedContent();
  }

  // 피드 내용 가져와서 html에 뿌리기
  getFeedContent(): void {
    this.db
      .doc$(`playUser/${this.playUserDataId}`)
      .pipe(take(1))
      .subscribe((playUser) => {
        this.feedData = playUser;
        console.log('FeedData', this.feedData);
      });
  }

  // 나의 nickname 가져오기
  getMyNickname(): void {
    this.db
      .doc$(`student/${this.myUid}`)
      .pipe(take(1))
      .subscribe((user) => {
        if (user) {
          this.myNickname = user.name;
        }
      });
  }

  // 나의 피드 내용 local에 저장하기
  downloadImg(): void {
    var node = document.getElementById(this.myFeedCardId);

    htmlToImage
      .toPng(node)
      .then((dataUrl: string) => {
        let link = document.createElement('a');
        link.download = 'feed';
        link.href = dataUrl;
        link.click();
      })
      .catch((error: Error) => {
        console.error('oops, something went wrong!', error);
      });
  }
}
