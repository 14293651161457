import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { NavigationExtras, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-play-select',
  templateUrl: './play-select.component.html',
  styleUrls: ['./play-select.component.css'],
})
export class PlaySelectComponent implements OnInit {
  constructor(private router: Router, private db: DbService) {}

  ngOnInit(): void {}

  // 개인 플레이 들어가기 전 이름 입력
  personalGame(): any {
    this.router.navigate(['/input-name'], {
      queryParams: {
        gameType: 'personal',
      },
    });
  }

  // 그룹 플레이 들어가기 전 선생님 코드부터 입력
  groupGame(): void {
    this.router.navigate(['/input-code']);
  }

  // 선생님일 경우 Login
  goTeacherLogin() {
    this.router.navigate(['/login']);
  }
}
