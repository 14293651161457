<header-share></header-share>
<main id="content">
  <div class="boasting_con">
    <p><strong>선정된 친구들의 피드에 하트를 눌러주세요!</strong></p>
  </div>

  <section class="myfeed_wrap">
    <h2 class="hidden">명예의 전당 글목록</h2>
    <div *ngIf="fame$ | async as fame">
      <div
        class="feed_card"
        *ngFor="let fameCard of fame | sortPipe; let i = index"
      >
        <div class="left_box">
          <span>{{ i + 1 }}</span>
          <img
            src="{{ fameCard?.cardId?.StepOneCard?.con.img }}"
            alt="스마트 거울"
          />
        </div>
        <div class="right_box">
          <div class="top_area">
            <div class="hashtag_area">
              <span>#{{ fameCard?.cardId?.StepTwoCard?.title }}</span>
              <span>#{{ fameCard?.cardId?.StepThreeCard?.card }}</span>
            </div>
            <div class="user_info">
              <span>{{ fameCard?.student?.name }}</span>
            </div>
          </div>

          <div class="txt_box">
            <p class="txt_area">
              {{ fameCard?.cardId?.successCard }}
            </p>
            <div class="txt_btn_box">
              <button class="heart" (click)="heartFame(fameCard)">
                <img
                  [src]="
                    fameCard.likeSwitch
                      ? 'assets/imgs/heart_icon_on.png'
                      : 'assets/imgs/heart_icon_off_w.png'
                  "
                />
                {{ fameCard?.likes ? fameCard?.likes.length : 0 }}
              </button>
              <button class="comment" (click)="famedetailReply(fameCard)">
                <img src="assets/imgs/comment_icon_w.png" alt="" />
                {{
                  fameCard?.cardId?.reviewsId
                    ? fameCard?.cardId?.reviewsId?.length
                    : 0
                }}
              </button>
            </div>
          </div>
        </div>

        <!--댓글란 버튼 누르면 -->
        <div class="bottom_box" *ngIf="fameCard.replySwitch">
          <div class="chat_area">
            <h3 class="hidden">댓글란</h3>
            <ul *ngFor="let reviewInfo of fameCard?.cardId.reviewsId">
              <li *ngIf="reviewInfo | doc: 'reviews' | async as review">
                <div class="chat_line" *ngIf="!review?.update">
                  <span class="uname">{{
                    (review.userId | doc: "student" | async)?.name
                      ? (review.userId | doc: "student" | async)?.name
                      : (review.userId | doc: "teacher" | async)?.name
                  }}</span>
                  <span class="chat"> {{ review.content }} </span>
                  <div class="time_area">
                    <span class="date">{{
                      review.dateCreated | date: "yy.MM.dd"
                    }}</span>
                    <button
                      class="report"
                      *ngIf="review.userId != userId"
                      (click)="
                        openReport(review.reviewsId, 'fame', fameCard.cardId)
                      "
                    >
                      신고
                    </button>
                    <!--내 글일때-->
                    <button
                      class="edit"
                      (click)="textUpdate(review)"
                      *ngIf="review.userId == userId"
                    >
                      수정
                    </button>
                  </div>
                </div>

                <!--내글일때 수정버튼 누르면 input 등장-->
                <div class="chat_line my_chat" *ngIf="review.update">
                  <span class="uname">{{
                    (review.userId | doc: "student" | async)?.name
                      ? (review.userId | doc: "student" | async)?.name
                      : (review.userId | doc: "teacher" | async)?.name
                  }}</span>
                  <div class="edit_chat">
                    <input
                      type="text"
                      [(ngModel)]="updateContent"
                      [placeholder]="review.content"
                      name=""
                      id=""
                      maxlength="20"
                    />
                    <div class="time_area">
                      <button
                        class="remove"
                        (click)="
                          openRemove(review, fameCard?.cardId?.groupPlayId)
                        "
                      >
                        삭제
                      </button>
                      <button class="edit" (click)="openRegister(review)">
                        등록
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="input_area">
            <input
              type="text"
              name=""
              id=""
              maxlength="20"
              placeholder="댓글을 입력해주세요. (20자 이내)"
              [(ngModel)]="reviews.content"
            />
            <button
              [disabled]="false"
              (click)="openReviewUpdate(fameCard?.cardId?.groupPlayId)"
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--//feed_card-->
  </section>
</main>
<footer-share></footer-share>
