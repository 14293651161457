import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-input-name',
  templateUrl: './input-name.component.html',
  styleUrls: ['./input-name.component.css'],
})
export class InputNameComponent implements OnInit {
  name: string = '';
  code: string = '';
  gameType: string = '';

  noDataSwitch: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db: DbService
  ) {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.code = params.code;
      this.gameType = params.gameType;
    });
  }

  ngOnInit(): void {}

  // 이름 입력을 하지 않았는지 체크
  get confirmName() {
    const str = this.name.replace(/\s/g, ''); // 위와 같이 모든 공백을 제거
    if (str.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  // ai월드 게임 시작
  async start(): Promise<void> {
    if (this.gameType == 'group') {
      this.goGropPlay();
    } else {
      this.goPersonalPlay();
    }
  }

  // 개인 플레이
  async goPersonalPlay(): Promise<void> {
    this.router.navigate(['/play-loading'], {
      queryParams: {
        name: this.name,
        gameType: this.gameType,
      },
    });
  }

  // 그룹플레이
  async goGropPlay(): Promise<void> {
    try {
      await this.checkName();
      this.noDataSwitch = false;
      this.router.navigate(['/play-loading'], {
        queryParams: {
          code: this.code,
          name: this.name,
          gameType: this.gameType,
        },
      });
    } catch (err) {
      this.noDataSwitch = true;
      this.name = '';
    }
  }

  // 해당 선생님 학생중에 이름이 중복된 학생이 있는 경우를 체크
  async checkName(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db
        .collection$(`teacher`, (ref) =>
          ref.where('teacherCode', '==', this.code)
        )
        .pipe(take(1))
        .subscribe(async (datas) => {
          const teacher: any = datas[0];
          // 선생님 코드의 학생들 이름 데이터들을 가져와서 중복체크
          const nameChk = await this.getStudentNames(teacher.groupStudent);
          if (nameChk) {
            resolve('ok');
          } else {
            reject('already use name');
          }
        });
    });
  }

  async getStudentNames(groupStudent: Array<string>): Promise<boolean> {
    const names$ = this.getNamesObs(groupStudent);
    return new Promise((resolve, reject) => {
      names$.pipe(take(1)).subscribe((users) => {
        let filteredUsers = users.filter((ele: any) => ele.name == this.name);

        if (filteredUsers.length == 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getNamesObs(groupStudent: Array<string>): Observable<any> {
    return of(groupStudent).pipe(
      switchMap((students) => {
        let reads$ = [];

        students.forEach((uid) => {
          const doc$ = this.db.doc$(`student/${uid}`);
          reads$.push(doc$);
        });

        if (reads$.length > 0) {
          return combineLatest(reads$);
        } else {
          return of([]);
        }
      })
    );
  }
}
