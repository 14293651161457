import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allSearch',
})
export class AllSearchPipe implements PipeTransform {
  transform(items: any[], searchTerm: string): unknown {
    if (searchTerm) {
      // 학교명 혹은 수업명으로 지난 수업 검색
      return items.filter(
        (keyword) =>
          keyword.className &&
          keyword.className.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    } else {
      return items;
    }
  }
}
