import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'loading-first',
  templateUrl: './loading-first.component.html',
  styleUrls: ['./loading-first.component.css'],
})
export class LoadingFirstComponent implements OnInit {
  constructor(public spinner: NgxSpinnerService) {}

  // loading 2분 5초동안 보여주기
  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2500);
  }
}
