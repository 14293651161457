import { async } from '@angular/core/testing';
import { InputNumberService } from './../../../services/input-number.service';
import { TimerService } from './../../../services/timer.service';
import { DbService, leftJoinDocument } from './../../../services/db.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDesignComponent } from '../../alert-design/alert-design.component';
import { AlertDesignJustWordComponent } from '../../alert-design-just-word/alert-design-just-word.component';
import { ChatBoxComponent } from '../../template/chat-box/chat-box.component';
import { map, take, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { combineLatest, of } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css'],
})
export class PlayComponent implements OnInit {
  userId;
  user$;

  test;
  teacherCode;

  feeds;

  timerStr = '';

  fame = {
    fameId: '',
    dateCreated: '',
    likes: [],
    cardId: '',
  };

  exit = {
    exitId: '',
    dateCreated: '',
    classStuCount: '',
    successFeed: [],
    startCreated: 0,
    endCreated: 0,
    teacherId: '',
  };

  countDown;

  start;
  end;

  cards$;
  class$;

  currentPlayId: string = '';
  currentStartTime;
  defaultMinute = 20;
  middleTime;

  timeroutSwitch: boolean = false;
  nextClass = 1;

  disableButton = false;
  addTime = true;

  constructor(
    public dialog: MatDialog,
    private auth: AuthService,
    private db: DbService,
    public inputnumber: InputNumberService,
    private common: CommonService,
    private timer: TimerService
  ) {
    this.userId = localStorage.getItem('userId');
    this.user$ = this.db.doc$(`teacher/${this.userId}`);

    this.db.updateAt(`teacher/${this.userId}`, {
      readySwitch: true,
    });

    this.user$.subscribe((data) => {
      this.teacherCode = data.teacherCode;
    });

    // 현재 그룹 피드들
    this.cards$ = this.db
      .collection$(`groupFeed`, (ref) =>
        ref.where('teacherId', '==', this.userId)
      )
      .pipe(
        switchMap((groupFeeds: any) => {
          if (groupFeeds.length > 0) {
            let reads$ = [];
            const feeds = groupFeeds[0].studentCard;

            // feeds => groupFeed/studentCard id들!
            feeds.forEach((feed) => {
              let tmp$ = this.db.doc$(`playUser/${feed}`);
              reads$.unshift(tmp$);
            });

            if (reads$.length > 0) {
              return combineLatest(reads$);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      )
      .pipe(
        map((cards: any) => {
          if (cards.length > 0) {
            // 그룹카드 좋아요 count
            cards.map((card) => {
              card.likeAll = card.teacherlike + card.like?.length;
              return card;
            });

            return cards.sort((a, b) => {
              return a.likeAll > b.likeAll ? -1 : a.likeAll < b.likeAll ? 1 : 0;
            });
          } else {
            return [];
          }
        })
      )
      .pipe(leftJoinDocument(this.db.afs, 'studentId', 'student'));
    // 어떤 학생이 작성했는지 left join document
  }

  ngOnInit(): void {}

  //추가 신청 얼럿
  openMoreTime(playSwitch, checkPlay): any {
    if (!checkPlay || this.addTime) {
      return;
    } else {
      if (!playSwitch) {
        return;
      } else {
        const dialogRef = this.dialog.open(AlertDesignComponent, {
          data: { type: 'moreTime', txt: '추가 시간을 선택해주세요' },
        });
        dialogRef.afterClosed().subscribe(async (data) => {
          if (data.data == 'ok') {
            if (data.addTime) {
              // 추가 시간 선택을 정상적으로 했다면

              let updateData: any = {
                minutes: firebase.default.firestore.FieldValue.arrayUnion({
                  id: new Date().getTime(),
                  addtimer: data.addTime,
                }),
                activeSwitch: true,
                reason: '',
              };

              // timer 체크
              this.db
                .updateAt(`playRoom/${this.currentPlayId}`, updateData)
                .then(() => {
                  this.timer.stop();
                  this.middleTime += data.addTime * 60000;
                  let calc = this.middleTime - new Date().getTime();
                  this.timer
                    .countdownSecond(Math.floor(calc / 1000))
                    .subscribe((data) => {
                      let minutes = data.minutes % 60;
                      let seconds = data.seconds;
                      ``;
                      this.timerStr = `${('0' + minutes.toString()).slice(
                        -2
                      )}:${('0' + seconds.toString()).slice(-2)}`;
                      if (this.timerStr == '00:00') {
                        this.timeroutSwitch = true;
                        this.endGame(`플레이 시간이 종료되었습니다.`);
                        this.timer.stop();
                      } else {
                        this.timeroutSwitch = false;
                      }
                    });
                });
            }
          }
        });
      }
    }
  }

  // 학생들 그룹피드로 이동 함수
  async openCloseClass() {
    if (this.nextClass == 1) {
      const dialogRef = this.dialog.open(AlertDesignComponent, {
        disableClose: true,
        data: { type: 'leftOk', txt: '학생들을 그룹피드로 이동시키겠습니까?' },
      });

      dialogRef.afterClosed().subscribe(async (data) => {
        if (data.data == 'ok') {
          // 학생들을 그룹피드로 이동시킴
          this.disableButton = true;
          this.addTime = true;
          this.nextClass = 2;
          this.timerStr = '';
          this.timer.stop();
          firebase.default.firestore().runTransaction((transaction) => {
            return transaction
              .get(
                firebase.default
                  .firestore()
                  .collection('playRoom')
                  .doc(this.currentPlayId)
              )
              .then(
                (eventDoc) => {
                  return transaction.update(
                    firebase.default
                      .firestore()
                      .collection('playRoom')
                      .doc(this.currentPlayId),
                    {
                      activeSwitch: false,
                      reason: '그룹피드로 이동하겠습니다.',
                    }
                  );
                },
                (err) => {}
              );
          });
        } else {
          this.nextClass = 1;
        }
      });
    } else {
      const dialogRef = this.dialog.open(AlertDesignComponent, {
        disableClose: true,
        data: { type: 'leftOk', txt: '수업을 종료하시겠습니까?' },
      });

      this.db
        .doc$(`groupFeed/${this.teacherCode}`)
        .pipe(take(1))
        .subscribe((data) => {
          this.exit.successFeed = data.studentCard;
        });

      this.cards$.pipe(take(1)).subscribe((data) => {
        this.fame.cardId = data[0].id;
      });

      dialogRef.afterClosed().subscribe(async (data) => {
        if (data.data == 'ok') {
          // 수업 종료
          this.nextClass = 1;
          this.timerStr = '';
          this.fame.fameId = await this.db.createFsId();
          this.fame.dateCreated = new Date().toISOString();

          this.db.updateAt(`fame/${this.fame.fameId}`, {
            ...this.fame,
          });

          this.endGame('선생님이 플레이를 종료하였습니다.');
          localStorage.setItem('gamestop', 'end');
          this.addTime = false;
          this.db.updateAt(`teacher/${this.userId}`, {
            groupStudent: [],
            playSwitch: false,
            readySwitch: false,
            addTimer: 0,
          });
          this.db.updateAt(`groupFeed/${this.teacherCode}`, {
            studentCard: [],
          });
        }
      });
    }
  }

  // 게임 종료 함수
  endGame(txt) {
    this.exit.exitId = this.db.createFsId();
    this.exit.endCreated = new Date().getTime();
    this.exit.teacherId = this.userId;

    if (txt == '선생님이 플레이를 종료하였습니다.') {
      this.db.updateAt(`exitClass/${this.exit.exitId}`, {
        ...this.exit,
      });

      this.students.forEach((student) => {
        firebase.default.firestore().runTransaction((transaction) => {
          return transaction
            .get(
              firebase.default.firestore().collection('student').doc(student)
            )
            .then(
              (eventDoc) => {
                return transaction.update(
                  firebase.default
                    .firestore()
                    .collection('student')
                    .doc(student),
                  {
                    currentPlay: '',
                    playGame: [],
                  }
                );
              },
              (err) => {}
            );
        });
      });

      this.timer.stop();
    }
    const dialogRef = this.dialog.open(AlertDesignJustWordComponent, {
      data: { txt: '플레이가 종료되었습니다.' },
      panelClass: 'end_game_pop',
    });

    firebase.default.firestore().runTransaction((transaction) => {
      return transaction
        .get(
          firebase.default
            .firestore()
            .collection('playRoom')
            .doc(this.currentPlayId)
        )
        .then(
          (eventDoc) => {
            return transaction.update(
              firebase.default
                .firestore()
                .collection('playRoom')
                .doc(this.currentPlayId),
              {
                activeSwitch: false,
                reason: txt,
              }
            );
          },
          (err) => {
            console.log('Error', err);
          }
        );
    });
  }

  // 댓글 창 함수
  openComment(code) {
    const dialogRef = this.dialog.open(ChatBoxComponent, {
      panelClass: 'chat_box',
      data: {
        feedCode: code,
      },
    });
  }

  // 게임 시작 함수
  students: any = [];
  gameStart(students) {
    this.students = students;

    this.disableButton = true;
    this.addTime = false;
    const now = new Date();

    const playGame = {
      playId: this.common.generateFilename(),
      teacherId: this.userId,
      teacherCode: this.teacherCode,
      dateCreated: now.toISOString(),
      activeSwitch: true,
      reason: '',
      studentIds: students,
      minutes: [],
    };

    this.currentStartTime = now;
    this.currentPlayId = playGame.playId;
    this.middleTime = now.getTime() + this.defaultMinute * 60000;

    let calc = this.middleTime - new Date().getTime();
    this.timer.countdownSecond(Math.floor(calc / 1000)).subscribe((data) => {
      let hours = Math.floor(data.minutes / 60);
      let minutes = data.minutes % 60;
      let seconds = data.seconds;

      this.timerStr = `${('0' + minutes.toString()).slice(-2)}:${(
        '0' + seconds.toString()
      ).slice(-2)}`;

      if (this.timerStr == '00:00') {
        this.timeroutSwitch = true;
        this.endGame(`플레이 시간이 종료되었습니다.`);
        this.timer.stop();
      } else {
        this.timeroutSwitch = false;
      }
    });

    this.db.updateAt(`playRoom/${playGame.playId}`, playGame);

    students.forEach((student) => {
      console.log('student', student);
      this.db.updateAt(`student/${student}`, {
        currentPlay: this.currentPlayId,
      });
    });

    this.exit.classStuCount = students.length;
    this.exit.startCreated = new Date().getTime();
    this.exit.dateCreated = new Date().toISOString();
    this.db.updateAt(`teacher/${this.userId}`, {
      playSwitch: true,
      addTimer: 0,
    });
  }

  // 좋아요 업데이트 함수
  ilike(data, alllike, tealike) {
    const index = alllike.indexOf(this.userId);
    if (index > -1) {
      this.db.updateAt(`playUser/${data}`, {
        like: firebase.default.firestore.FieldValue.arrayUnion(this.userId),
        teacherlike: ++tealike,
      });
    } else {
      this.db.updateAt(`playUser/${data}`, {
        like: firebase.default.firestore.FieldValue.arrayUnion(this.userId),
      });
    }
  }

  likeTF(all) {
    if (all) {
      const index = all.indexOf(this.userId);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
}
